import { Result } from 'antd';
import { FC, ReactNode } from 'react';

const OnEmpty: FC<{ title?: string; subTitle?: string; extra?: ReactNode }> = ({
  title = 'No items available',
  subTitle = 'Try adjusting your filters or check back later',
  extra,
}) => <Result status="404" title={title} subTitle={subTitle} extra={extra} />;

export default OnEmpty;
