/*So in REG_registrationWindows 'billed' is used for ELO-P.
If true, then the registration fee is billed to the organization school district.
'waviedForStaff' is used to waive registration fees for STAR staff.
This is mostly always set to true. And the 'waivedForSubsidies' is used for PCOE etc. This is mostly always set to false.
The only fees that are ever paid during registration are the registration fees.*/

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Skeleton,
  Button,
  Typography,
  Alert,
  Card,
  List,
  Divider,
  Space,
} from 'antd';
import OnError from '../../../../components/OnError';

// Hooks
import { useFetchUser } from '../../../../hooks/useFetchUser';
import { useGetStudents } from '../../../../hooks/useGetStudents';
import { useGetSchedules } from '../../../../hooks/useGetSchedules';
import { useGetProgramListings } from '../../../../hooks/useGetProgramListings';
import { useGetRegistrationWindows } from '../../../../hooks/useGetRegistrationWindows';
import { useGetRegistrationWindowsBilledTo } from '../../../../hooks/useGetRegistrationWindowsBilledTo';
import { useGetOrganizationalAccounts } from '../../../../hooks/useGetOrganizationalAccounts';
import { useGetConcurrentEnrollmentDiscounts } from '../../../../hooks/useGetConcurrentEnrollmentDiscounts';
import { useGetScheduleBilledTo } from '../../../../hooks/useGetScheduleBilledTo';
import { useGetRegistrationPaymentStatus } from '../../../../hooks/useGetRegistrationPaymentStatus';

// Local schedule hooks
import { useGet as useGetLocalSchedule } from '../../../../hooks/localSchedule/useGet';

import NextStepButton from '../../components/NextStepButton';

const { Title, Paragraph, Text } = Typography;

/**
 * Basic currency formatting
 */
export const dollars = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/**
 * Simple helpers for summarizing selected days and checking if at least one day is selected.
 */
export const scheduleLib = () => {
  const summary = (selection: any) => {
    let scheduleString = '';
    if (selection.monday) scheduleString += 'M/';
    if (selection.tuesday) scheduleString += 'T/';
    if (selection.wednesday) scheduleString += 'W/';
    if (selection.thursday) scheduleString += 'TH/';
    if (selection.friday) scheduleString += 'F/';
    return scheduleString.slice(0, -1); // remove trailing slash
  };

  const valid = (selection: any) => {
    if (!selection) return null;
    const totalDaysSelected = [
      selection.monday,
      selection.tuesday,
      selection.wednesday,
      selection.thursday,
      selection.friday,
    ].filter(Boolean).length;
    return totalDaysSelected > 0 ? selection : null;
  };

  return [summary, valid];
};

export default function ConfirmSchedule({
  onNextStep,
}: {
  onNextStep?: () => void;
}) {
  const navigate = useNavigate();

  // Local schedule
  const localSchedule = useGetLocalSchedule();

  // Load data via new hooks
  const {
    data: user,
    isLoading: loadingUser,
    error: userError,
  } = useFetchUser();
  const {
    data: students,
    isLoading: loadingStudents,
    error: studentsError,
  } = useGetStudents();
  const {
    data: schedulesData,
    isLoading: loadingSchedules,
    error: schedulesError,
  } = useGetSchedules();
  const {
    data: programListingsData,
    isLoading: loadingProgramListings,
    error: programListingsError,
  } = useGetProgramListings();
  const {
    data: regWindows,
    isLoading: loadingRegWindows,
    error: regWindowsError,
  } = useGetRegistrationWindows();
  const {
    data: regWindowsBilledToData,
    isLoading: loadingRegBilledTo,
    error: regWindowsBilledToError,
  } = useGetRegistrationWindowsBilledTo();
  const {
    data: orgAccounts,
    isLoading: loadingOrgAccounts,
    error: orgAccountsError,
  } = useGetOrganizationalAccounts();
  const {
    data: concurrencyDiscounts,
    isLoading: loadingDiscounts,
    error: concurrencyError,
  } = useGetConcurrentEnrollmentDiscounts();
  const {
    data: scheduleBilledToData,
    isLoading: loadingScheduleBilledTo,
    error: scheduleBilledToError,
  } = useGetScheduleBilledTo();
  const {
    data: registrationPaymentStatus,
    isLoading: loadingPaymentStatus,
    error: paymentStatusError,
  } = useGetRegistrationPaymentStatus();

  // const removeLocalSchedule = useRemoveLocalSchedule();

  // Combined loading/error checks
  const isLoading =
    loadingUser ||
    loadingStudents ||
    loadingSchedules ||
    loadingProgramListings ||
    loadingRegWindows ||
    loadingRegBilledTo ||
    loadingOrgAccounts ||
    loadingDiscounts ||
    loadingScheduleBilledTo ||
    loadingPaymentStatus;

  if (isLoading) {
    return (
      <div role="progressbar">
        <Skeleton active />
      </div>
    );
  }

  // If any critical data is missing or there's an error
  if (
    userError ||
    studentsError ||
    schedulesError ||
    programListingsError ||
    regWindowsError ||
    regWindowsBilledToError ||
    orgAccountsError ||
    concurrencyError ||
    scheduleBilledToError ||
    paymentStatusError ||
    !user ||
    !students ||
    !schedulesData ||
    !programListingsData ||
    !regWindows ||
    !regWindowsBilledToData ||
    !orgAccounts ||
    !concurrencyDiscounts ||
    !scheduleBilledToData ||
    !registrationPaymentStatus
  ) {
    return (
      <OnError
        title="Missing or Failed to Load Required Data"
        subTitle="Please try again or contact support."
        extra={
          <Button type="dashed" onClick={() => navigate('/scheduling')}>
            Go Back
          </Button>
        }
        status={404}
      />
    );
  }

  // localSchedule might be undefined if not set yet
  if (!localSchedule || !localSchedule.selections) {
    return (
      <OnError
        title="No Schedule Found"
        subTitle="Please choose a schedule before continuing."
        extra={
          <Button type="dashed" onClick={() => navigate('/scheduling')}>
            Go Back
          </Button>
        }
      />
    );
  }

  // Access helper functions
  const [scheduleSummary, validSelection] = scheduleLib();

  /**
   * Identify unique registration windows for these selections.
   * We look up the window by: selection.program.registrationWindowId.
   */
  function getDistinctRegistrationWindowsForStudent(selections: any[]) {
    if (!regWindows) return [];
    const windows = selections
      .map((sel) => {
        const rwId = sel.program?.registrationWindowId;
        if (!rwId) return null;
        return regWindows.find((rw: any) => +rw.id === +rwId);
      })
      .filter((window, idx, self) => {
        return (
          window && self.findIndex((w: any) => +w.id === +window.id) === idx
        );
      });

    return windows;
  }

  /**
   * Display string for a registration fee
   */
  function registrationFee(
    currentRegistration: any,
    studentRegistrations: any[] = [],
    studentsSubsidyEligible: any
  ) {
    if (!currentRegistration) return null;

    // Waived for staff
    if (currentRegistration.waivedForStaff && user && (user as any).staff) {
      return 'Waived for Staff';
    }

    // Check if already paid via API response
    if (
      registrationPaymentStatus &&
      registrationPaymentStatus[currentRegistration.id]
    ) {
      return 'Already Paid';
    }

    // Fallback to old method - check student registrations
    const registrations =
      studentRegistrations?.map((sr) => +sr.registrationWindowId) || [];

    if (registrations.includes(+currentRegistration.id)) {
      return 'Already Paid';
    }

    // Billed to org
    if (studentsSubsidyEligible?.id) {
      const billedToReg =
        regWindowsBilledToData?.[currentRegistration.id] || [];
      for (const b of billedToReg) {
        const orgAccount = orgAccounts?.find(
          (acc: any) => +b.userId === +acc.userId
        );
        if (!orgAccount) continue;
        if (!b.conditional) {
          return `Billed to ${orgAccount.accountName}`;
        }
      }
    }

    // Billed separately
    if (currentRegistration.billed) {
      return 'Billed Separately';
    }

    // Default
    return dollars.format(currentRegistration.amount);
  }

  /**
   * Calculate tuition cost for a schedule selection
   */
  function calcFee(
    schedule: any,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    discount: any[],
    schedulesBilledTo: any[],
    orgAccount: any,
    allSelectionsForStudent: any[]
  ) {
    // Check if schedule is billed to an org
    let isBilled = false;
    schedulesBilledTo.forEach((b: any) => {
      if (
        +b.scheduleId === +schedule.id &&
        orgAccount &&
        +b.userId === +orgAccount.userId
      ) {
        isBilled = true;
      }
    });
    if (isBilled) return 0;

    // Check concurrency discount
    let useDiscount = false;
    if (discount?.length && allSelectionsForStudent?.length) {
      allSelectionsForStudent.forEach((sel) => {
        // If sel.scheduleId matches discount.concurrentId
        if (
          discount.some(
            (disc: any) => +sel.scheduleId === +disc.concurrentId
          ) &&
          [
            sel.monday,
            sel.tuesday,
            sel.wednesday,
            sel.thursday,
            sel.friday,
          ].filter(Boolean).length > 1
        ) {
          useDiscount = true;
        }
      });
    }

    const daysCount = [monday, tuesday, wednesday, thursday, friday].filter(
      Boolean
    ).length;
    if (daysCount < 1) return 0;

    const baseFee = Number(schedule.fee) || 0;

    // If discount applies
    if (useDiscount && discount.length) {
      switch (daysCount) {
        case 1:
          return Number(discount[0].oneDay) || 0;
        case 2:
          return Number(discount[0].twoDay) || 0;
        case 3:
          return Number(discount[0].threeDay) || 0;
        case 4:
          return Number(discount[0].fourDay) || 0;
        case 5:
          return Number(discount[0].fiveDay) || 0;
        default:
          return 0;
      }
    } else {
      // Normal schedule pricing
      switch (daysCount) {
        case 1:
          return (Number(schedule.oneDay) || 0) + baseFee;
        case 2:
          return (Number(schedule.twoDay) || 0) + baseFee;
        case 3:
          return (Number(schedule.threeDay) || 0) + baseFee;
        case 4:
          return (Number(schedule.fourDay) || 0) + baseFee;
        case 5:
          return (Number(schedule.fiveDay) || 0) + baseFee;
        default:
          return 0;
      }
    }
  }

  /**
   * Finalize enrollment
   */
  function enroll() {
    // If you have a new approach to collecting payment info or resetting timers, do it here.
    if (onNextStep) {
      onNextStep();
    } else {
      navigate('/finalize/payment');
    }
  }

  // --- Render a more polished UI ---
  return (
    <div>
      {/* Top Title */}
      <Title level={3} style={{ marginBottom: 16 }}>
        Review Your Selected Schedule
      </Title>

      {/* Info Alert */}
      <Alert
        type="info"
        message="Please review your selections carefully."
        description={
          <div>
            <Paragraph>
              By completing enrollment for these schedules, you agree to pay all
              associated tuition and fees when they are due.
            </Paragraph>
            {!user || !(user as any).elop?.id ? (
              <Paragraph strong style={{ marginBottom: 0 }}>
                Only registration fees will be due today.
              </Paragraph>
            ) : null}
          </div>
        }
        showIcon
        style={{ marginBottom: 24 }}
      />

      {/* ELO-P warning */}
      {orgAccounts.length > 0 && (
        <Alert
          data-testid="elo-p-warning"
          type="warning"
          message="ELO-P Funding"
          description={
            <Paragraph style={{ marginBottom: 0 }}>
              You may qualify for ELO-P funding. Some or all tuition could be
              covered by your district. Any covered fees will appear as $0 or
              &quot;Billed to &lt;District&gt;&quot;.
            </Paragraph>
          }
          showIcon
          style={{ marginBottom: 24 }}
        />
      )}

      <Space direction="vertical" style={{ width: '100%' }} size="large">
        {students.map((student: any, index: number) => {
          // Filter localSchedule.selections for this student
          const selectionsForThisStudent = localSchedule.selections.filter(
            (sel: any) => +sel.studentId === +student.id
          );
          if (!selectionsForThisStudent.length) return null;

          // Distinct windows
          const distinctWindows = getDistinctRegistrationWindowsForStudent(
            selectionsForThisStudent
          );

          return (
            <Card
              key={index}
              title={
                <Title level={4} style={{ margin: 0 }}>
                  {student.firstName}
                </Title>
              }
              bordered={true}
            >
              {/* Registration Fees List */}
              {!!distinctWindows.length && (
                <>
                  <Text strong>Registration Fees - DUE TODAY</Text>
                  <List
                    style={{ marginBottom: 16, marginTop: 8 }}
                    dataSource={distinctWindows}
                    renderItem={(regWindow: any) => {
                      const feeText = registrationFee(
                        regWindow,
                        student.registrations,
                        student.studentsSubsidyEligible
                      );
                      return (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <Text>{regWindow?.title} Registration Fee</Text>
                            }
                            description={feeText || 'N/A'}
                          />
                        </List.Item>
                      );
                    }}
                  />
                  <Divider style={{ margin: '12px 0' }} />
                </>
              )}

              {/* Tuition for each selection */}
              <Text strong>Tuition - DUE 1st DAY OF CAMP</Text>
              <List
                style={{ marginTop: 8 }}
                dataSource={selectionsForThisStudent}
                renderItem={(sel: any, idx: number) => {
                  const validSel = validSelection(sel);
                  if (!validSel) return null;

                  const schedule = sel.schedule;
                  const program = sel.program;
                  if (!schedule || !program) return null;

                  // concurrencyDiscounts is keyed by schedule ID
                  const discountForThisSchedule =
                    concurrencyDiscounts[schedule.id] || [];

                  // Calculate fee
                  const fee = calcFee(
                    schedule,
                    sel.monday,
                    sel.tuesday,
                    sel.wednesday,
                    sel.thursday,
                    sel.friday,
                    discountForThisSchedule,
                    scheduleBilledToData,
                    student.studentsSubsidyEligible?.id
                      ? orgAccounts[0]
                      : undefined,
                    selectionsForThisStudent
                  );

                  return (
                    <List.Item key={idx}>
                      <List.Item.Meta
                        title={
                          <>
                            <Text strong>
                              {program.title}{' '}
                              {program.group ? `(${program.group})` : ''}
                            </Text>
                            <div style={{ fontSize: 13 }}>
                              {schedule.name} - Starts {program.startDate}
                            </div>
                          </>
                        }
                        description={
                          <div style={{ marginTop: 4 }}>
                            <Text type="secondary">
                              Days Selected: {scheduleSummary(sel)}
                            </Text>
                          </div>
                        }
                      />
                      <div>
                        {dollars.format(fee)}
                        {program.monthly ? ' / month' : ' total'}
                      </div>
                    </List.Item>
                  );
                }}
              />
            </Card>
          );
        })}
      </Space>

      <Divider />

      <NextStepButton disabled={false} onClick={enroll} />
    </div>
  );
}
