import React from 'react';
import { Alert } from 'antd';
import { useGetStudentEnrollmentStatus } from '../hooks/useGetStudentEnrollmentStatus';

interface EnrollmentStatusMessageProps {
  studentId: number | null;
  scheduleId: number | null;
}

/**
 * Componente que exibe uma mensagem de alerta quando um estudante
 * já está matriculado em um programa ou tem horário selecionado
 */
const EnrollmentStatusMessage: React.FC<EnrollmentStatusMessageProps> = ({
  studentId,
  scheduleId,
}) => {
  const { data: enrollmentStatus, isLoading } = useGetStudentEnrollmentStatus(
    studentId,
    scheduleId
  );

  // Não mostrar nada se estiver carregando ou não houver status
  if (isLoading || !enrollmentStatus) {
    return null;
  }

  // Não mostrar nada se o estudante não estiver matriculado,
  // não tiver horário pendente e não tiver conflito de horário
  if (
    !enrollmentStatus.isEnrolled &&
    !enrollmentStatus.isPending &&
    !enrollmentStatus.hasTimeConflict
  ) {
    return null;
  }

  // Determinar o tipo de alerta com base no status
  let alertType = 'warning';
  if (enrollmentStatus.isEnrolled) {
    alertType = 'error';
  } else if (enrollmentStatus.hasTimeConflict) {
    alertType = 'error';
  }

  return (
    <Alert
      message={enrollmentStatus.message}
      type={alertType as 'error' | 'warning'}
      showIcon
      style={{ marginBottom: 16 }}
    />
  );
};

export default EnrollmentStatusMessage;
