import React, { FC } from 'react';
import { Button, Card, Skeleton } from 'antd';
import { Col, Row, Table } from 'react-bootstrap';
import { Pt3 } from '../../../components/P';
import { ProgramH2 } from '../../../components/ProgramH2';
import { CurrentlyEnrollingProgram } from '../SchedulingPage';

import { useFetchUser } from '../../../hooks/useFetchUser';
import { useGetRegistrationWindows } from '../../../hooks/useGetRegistrationWindows';
import { useGetSchedules } from '../../../hooks/useGetSchedules';
import { useGetEnrollingSchools } from '../../../hooks/useGetEnrollingSchools';
import { useGetGrades } from '../../../hooks/useGetGrades';
import { useGetOrganizationalAccounts } from '../../../hooks/useGetOrganizationalAccounts';
import { useGetScheduleBilledTo } from '../../../hooks/useGetScheduleBilledTo';
import { useGetPromotionDay } from '../../../hooks/useGetPromotionDay';
import dayjs from 'dayjs';
import MoreInfoModal from '../modals/MoreInfo';

/**
 * Schedule data shape from the API. You can adjust this type
 * if the API returns additional fields you need to use.
 */
interface Schedule {
  id: number;
  startTime: string;
  endTime: string;
  gradeMinimum: number;
  gradeMaximum: number;
  fee: string;
  oneDay: string;
  twoDay: string;
  threeDay: string;
  fourDay: string;
  fiveDay: string;
}

/**
 * Grade data shape, as returned from useGetGrades().
 */
interface Grade {
  id: number;
  shortGrade: string;
  grade: string;
}

/**
 * Registration window data shape, as returned from useGetRegistrationWindows().
 */
interface RegistrationWindow {
  id: number;
  startDate: string;
}

/**
 * Organizational account shape. Adjust if the API changes.
 */
interface OrganizationalAccount {
  userId: number;
  id: number;
  displayName: string;
}

/**
 * Defines the props for the Program component.
 */
interface ProgramProps {
  /** Background color for the program schedule header. */
  backgroundColor: string;
  /** The current program being displayed. */
  program: CurrentlyEnrollingProgram;
}

/**
 * Converts an "HH:MM:SS" time string to a comparable numeric value.
 *
 * @param time - A string in "HH:MM:SS" format.
 * @returns A numeric value that can be used for sorting times.
 */
function convertTimeToNumber(time: string): number {
  const [h, m, s] = time.split(':');
  const hours = Number(h);
  const minutes = Number(m) / 60;
  // Note: "seconds" division is by 360, not 3600.
  // If this was intentional, keep it. Otherwise, adjust to / 3600 for accuracy.
  const seconds = Number(s) / 360;
  return hours + minutes + seconds;
}

/**
 * Formats a date string into either a "nice" format or a "default" (MM/DD/YYYY) format.
 *
 * @param date - The date string to format.
 * @param formatType - The format type, "nice" or "default".
 * @returns A formatted date string.
 */
function formatDate(
  date: string,
  formatType: 'nice' | 'default' = 'nice'
): string {
  const options =
    formatType === 'nice'
      ? { timeZone: 'UTC', year: 'numeric', month: 'short', day: 'numeric' }
      : { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit' };

  return new Date(date).toLocaleDateString('en-US', options);
}

/**
 * Determines if we should "promote" the student's grade for the current year,
 * based on the registration window start date and whether it's before or after
 * the district's "promotion day".
 *
 * @param regWindow - The registration window data for the program.
 * @param beforePromotionDay - Whether the current day is before the promotion day.
 * @returns True if the current year needs promotion, otherwise false.
 */
function shouldPromoteThisYear(
  regWindow: RegistrationWindow | undefined,
  beforePromotionDay: boolean
): boolean {
  if (!regWindow) return false;
  const currentYear = new Date().getFullYear();
  const regYear = new Date(regWindow.startDate).getFullYear();
  if (currentYear > regYear) return false;
  return beforePromotionDay;
}

/**
 * Calculates the overall min and max grade from the list of schedules.
 * If promotion is needed, subtracts 1 from those boundaries.
 *
 * @param schedules - The list of schedules for this program.
 * @param promote - Whether the year should be promoted.
 * @returns An object containing min and max grade values.
 */
function getGradeRange(schedules: Schedule[], promote: boolean) {
  if (!schedules.length) return { min: 0, max: 0 };
  const minGrade = Math.min(...schedules.map((s) => s.gradeMinimum));
  const maxGrade = Math.max(...schedules.map((s) => s.gradeMaximum));
  return {
    min: promote ? minGrade - 1 : minGrade,
    max: promote ? maxGrade - 1 : maxGrade,
  };
}

/**
 * Extracts numeric fees from a schedule, ignoring empty or invalid values, and
 * also filtering out any zeroes (assuming they mean "no fee").
 *
 * @param schedule - The schedule to extract fees from.
 * @returns A list of numeric fees found in the schedule (excludes 0).
 */
function getNumericFees(schedule: Schedule): number[] {
  const { fee, oneDay, twoDay, threeDay, fourDay, fiveDay } = schedule;
  return [fee, oneDay, twoDay, threeDay, fourDay, fiveDay]
    .map(Number)
    .filter((f) => !Number.isNaN(f) && f !== 0);
}

/**
 * From all schedules, calculates:
 * - The lowest fee (minFee)
 * - The highest fee (maxFee)
 * - The lowest "maximum fee" among all schedules (minMaxFee)
 *
 * @param schedules - The list of schedules.
 * @returns An object with minFee, maxFee, and minMaxFee.
 */
function getFeeRange(schedules: Schedule[]) {
  if (!schedules.length) {
    return { minFee: 0, maxFee: 0, minMaxFee: 0 };
  }

  const feesPerSchedule = schedules
    .map(getNumericFees)
    .filter((fees) => fees.length > 0);
  if (!feesPerSchedule.length) {
    // If after removing zeros, we have no valid fees at all:
    return { minFee: Infinity, maxFee: -Infinity, minMaxFee: -Infinity };
  }

  const minFee = Math.min(...feesPerSchedule.map((fees) => Math.min(...fees)));
  const maxFee = Math.max(...feesPerSchedule.map((fees) => Math.max(...fees)));
  const minMaxFee = Math.min(
    ...feesPerSchedule.map((fees) => Math.max(...fees))
  );

  return { minFee, maxFee, minMaxFee };
}

/**
 * Finds the school's name based on the program's schoolId.
 *
 * @param enrollingSchools - The list of available schools from the API.
 * @param program - The currently enrolling program.
 * @returns The name of the school if found; otherwise an empty string.
 */
function findSchoolName(
  enrollingSchools: Array<{ id: number; name: string }>,
  program: CurrentlyEnrollingProgram
): string {
  const school = enrollingSchools.find(
    (s) => s.id === Number(program.schoolId)
  );
  return school?.name ?? '';
}

/**
 * Renders an explanatory snippet about the grade range, depending on
 * whether the system is before the official promotion day.
 *
 * @param beforePromotionDay - True if it's before the district's promotion day; false if after.
 * @returns A React fragment describing the grade addendum text.
 */
function renderGradeAddendum(beforePromotionDay: boolean): JSX.Element {
  return beforePromotionDay ? (
    <>
      {' '}
      in the <strong>current</strong> school year.
    </>
  ) : (
    <> in the Fall.</>
  );
}

/**
 * Calculates an approximate maximum "selectable" tuition value for display.
 * If the program has a wide range of fees, we sum minMaxFee + maxFee
 * as a rough upper bound.
 *
 * @param minMaxFee - The lowest "maximum" fee among schedules.
 * @param maxFee - The absolute highest fee among schedules.
 * @returns A single numeric value representing the approximate max.
 */
function getApproximateMaxSelectableFee(
  minMaxFee: number,
  maxFee: number
): number {
  return minMaxFee === maxFee ? maxFee : minMaxFee + maxFee;
}

/**
 * Displays a single Program card. Shows program name, scheduling info,
 * associated fees, grade range, and location. Also handles ELOP-funding eligibility.
 */
const Program: FC<ProgramProps> = ({ backgroundColor, program }) => {
  const [moreInfoModal, setMoreInfoModal] = React.useState(false);
  // Fetch user and relevant data from hooks
  const { data: userData, isLoading: userDataLoading } = useFetchUser();
  const {
    data: registrationWindowsData,
    isLoading: registrationWindowsLoading,
  } = useGetRegistrationWindows();
  const { data: allSchedules, isLoading: allSchedulesLoading } =
    useGetSchedules();
  const {
    data: enrollingSchoolsData = [],
    isLoading: enrollingSchoolsLoading,
  } = useGetEnrollingSchools();
  const { data: allGradesData = [], isLoading: allGradesLoading } =
    useGetGrades();
  const {
    data: organizationalAccountsData = [],
    isLoading: organizationalAccountsLoading,
  } = useGetOrganizationalAccounts();
  const {
    data: schedulesBilledToData = [],
    isLoading: schedulesBilledToLoading,
  } = useGetScheduleBilledTo();

  const isLoading =
    userDataLoading ||
    registrationWindowsLoading ||
    allSchedulesLoading ||
    enrollingSchoolsLoading ||
    allGradesLoading ||
    organizationalAccountsLoading ||
    schedulesBilledToLoading;

  // Identify primary user and organizational account
  const primaryGuardian = userData?.PrimaryGuardian;
  const primaryOrgAccountId =
    primaryGuardian?.organizationalAccounts?.[0]?.organizationalAccountId;
  const organizationalAccount = React.useMemo<
    OrganizationalAccount | undefined
  >(
    () =>
      organizationalAccountsData.find((acc) => acc.id === primaryOrgAccountId),
    [organizationalAccountsData, primaryOrgAccountId]
  );

  // Fetch the relevant registration window for this program
  const registrationWindow = React.useMemo<RegistrationWindow | undefined>(
    () =>
      registrationWindowsData?.find(
        (win) => win.id === program.registrationWindowId
      ),
    [registrationWindowsData, program.registrationWindowId]
  );

  // Sort schedules by their start and end times
  const schedules = React.useMemo<Schedule[]>(() => {
    if (!allSchedules || !allSchedules[program.id]) return [];
    return [...allSchedules[program.id]].sort((a, b) => {
      return (
        convertTimeToNumber(a.startTime) - convertTimeToNumber(b.startTime) ||
        convertTimeToNumber(a.endTime) - convertTimeToNumber(b.endTime)
      );
    });
  }, [allSchedules, program.id]);

  const getPromotionDay = useGetPromotionDay();
  const promotionDay = dayjs(getPromotionDay.data);
  const beforePromotionDay = getPromotionDay.data
    ? dayjs().isBefore(promotionDay)
    : false;

  const promote = shouldPromoteThisYear(registrationWindow, beforePromotionDay);

  // Get the grade range to display
  const { min: gradeMin, max: gradeMax } = getGradeRange(schedules, promote);

  // Map grade IDs to their details for easy lookup
  const gradeMap = React.useMemo<Record<number, Grade>>(() => {
    const map: Record<number, Grade> = {};
    allGradesData.forEach((g) => {
      // @ts-ignore
      map[g.id] = g;
    });
    return map;
  }, [allGradesData]);

  // Gather tuition fee range
  const { minFee, maxFee, minMaxFee } = getFeeRange(schedules);

  // Derive the school name from program.schoolId
  const schoolName = findSchoolName(enrollingSchoolsData, program);

  // Check if the user is ELOP-eligible for this program
  const isElopEligible = React.useMemo<boolean>(() => {
    if (
      !schedules.length ||
      !schedulesBilledToData.length ||
      !organizationalAccount
    )
      return false;
    return schedules.some((schedule) =>
      schedulesBilledToData.some(
        (bill) =>
          bill.scheduleId === schedule.id &&
          bill.userId === organizationalAccount.userId
      )
    );
  }, [schedules, schedulesBilledToData, organizationalAccount]);

  // Extract short grade labels for display
  const minShortGrade = gradeMap[gradeMin]?.shortGrade;
  const maxShortGrade = gradeMap[gradeMax]?.shortGrade;
  const minFullGrade = gradeMap[gradeMin]?.grade;

  // Decide how to display the grade range line (single grade vs range)
  const gradeLabel =
    minShortGrade === maxShortGrade
      ? minFullGrade
      : `${minShortGrade} - ${maxShortGrade} Grade`;

  // Handle tuition text:
  // - "No Fee For Family" if minFee, maxFee are Infinity/-Infinity after filtering
  // - A range with approximate upper bound if minFee != maxFee
  // - A single fee if minFee == maxFee
  let tuitionText = '';

  const noValidFees = minFee === Infinity && maxFee === -Infinity;

  if (noValidFees || (!minFee && !maxFee)) {
    tuitionText = 'No Fee For Family';
  } else {
    if (minFee !== maxFee) {
      const approxMax = getApproximateMaxSelectableFee(minMaxFee, maxFee);
      tuitionText = `Approximately ${minFee.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })} - ${approxMax.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })} based on selected schedule`;
    } else {
      tuitionText = minFee.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
  }

  if (isLoading) {
    return (
      <Card style={{ borderRadius: 0 }}>
        <Skeleton active={true} />
      </Card>
    );
  }

  return (
    <Card
      style={{ borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 0, paddingTop: '16px' }}
    >
      {gradeMap[gradeMin] && gradeMap[gradeMax] && (
        <MoreInfoModal
          isOpen={moreInfoModal}
          onClose={() => setMoreInfoModal(false)}
          program={program}
          gradeMinimum={gradeMap[gradeMin]}
          gradeMaximum={gradeMap[gradeMax]}
          onClearSelection={() => {}}
          onAddToStudentSchedule={() => {}}
          onAddToSchedule={() => {}}
          onRefreshStudents={() => {}}
          onInitialPayload={() => {}}
        />
      )}
      <Row>
        <Col md={8}>
          <ProgramH2>
            {program.title}{' '}
            {isElopEligible && (
              <strong>{organizationalAccount?.displayName} Funded</strong>
            )}
          </ProgramH2>
        </Col>
        {gradeMap[gradeMin] && gradeMap[gradeMax] && (
          <Col md={4} className="text-right">
            <Button
              type="primary"
              className={'btn-sac'}
              onClick={() => setMoreInfoModal(true)}
            >
              More Info
            </Button>
          </Col>
        )}
      </Row>

      <Pt3>
        <Table>
          <thead>
            <tr>
              <th style={{ backgroundColor }}>{program.generalTime}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ borderBottom: 0 }}>
                {/* School location */}
                {schoolName}
                <br />
                {/* Grade range */}
                {gradeLabel}
                {renderGradeAddendum(beforePromotionDay)}
                <br />
                {/* Date range */}
                {formatDate(program.startDate, 'nice')} -{' '}
                {formatDate(program.endDate, 'nice')}
                <br />
                {/* Tuition range */}
                <div style={{ paddingTop: '6px' }}>
                  <strong>Tuition:</strong>
                  <br />
                  {tuitionText}
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </Pt3>
    </Card>
  );
};

export default Program;
