import { useGet, usePost } from './useApi';

/**
 * Interface for the enrollment status verification API response
 */
interface EnrollmentStatusResponse {
  isEnrolled: boolean;
  isPending: boolean;
  hasTimeConflict: boolean;
  message: string;
}

/**
 * Interface for weekday selection conflict verification
 */
export interface WeekdaySelection {
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
}

/**
 * Hook that checks if a student is already enrolled in a specific program
 * or has the schedule selected
 *
 * @param studentId - ID of the student to verify
 * @param scheduleId - ID of the schedule to verify
 * @returns Enrollment status data, loading state, and possible errors
 */
export const useGetStudentEnrollmentStatus = (
  studentId?: number | null,
  scheduleId?: number | null
) => {
  // Build the endpoint only if studentId and scheduleId are valid
  const endpoint =
    studentId && scheduleId
      ? `/v1/auth/reg/student/${studentId}/is-enrolled-in-schedule/${scheduleId}`
      : '';

  return useGet<EnrollmentStatusResponse>(endpoint, {
    // Unique cache key based on IDs
    queryKey: ['studentEnrollmentStatus', studentId, scheduleId],
    // Don't revalidate automatically when mounting the component
    refetchOnMount: false,
    // Don't revalidate automatically when reconnecting
    refetchOnReconnect: false,
    // Don't revalidate at regular intervals
    refetchInterval: false,
    // Avoid displaying stale data while reloading
    keepPreviousData: false,
    // Only enable the query if studentId and scheduleId are valid
    enabled: !!studentId && !!scheduleId,
  });
};

/**
 * Hook that checks if the selected days for a student create schedule conflicts
 *
 * @returns A mutation function to check for conflicts, along with loading state and errors
 */
export const useCheckScheduleConflicts = () => {
  const endpoint = '/v1/auth/reg/student/check-schedule-conflicts';
  return usePost<
    EnrollmentStatusResponse,
    {
      studentId: number;
      scheduleId: number;
      selectedDays: WeekdaySelection;
    }
  >(endpoint);
};
