import { Divider, Steps as AntDSteps } from 'antd';
import { FC } from 'react';

const Steps: FC<{ current: number }> = ({ current }) => {
  return (
    <>
      <AntDSteps
        current={current}
        items={[
          {
            title: 'Confirm Policies',
          },
          {
            title: 'Reserve Schedule',
          },
          {
            title: 'Pay Fees',
          },
          {
            title: 'Sign Documentation',
          },
        ]}
      />
      <div className={'text-center pt-4'} style={{ fontSize: '12px' }}>
        <p>
          There are several steps to complete enrollment. Please take your time
          to ensure all steps are completed properly.
        </p>
        <p>
          If you do not complete each section{' '}
          <b>
            enrollment will not be complete, and your students will be
            disenrolled from all selections.
          </b>
        </p>
      </div>

      <Divider />
    </>
  );
};
export default Steps;
