import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Skeleton, Row, Col, Typography, Button } from 'antd';

import OnError from '../../../components/OnError';
import {
  StudentWithProps,
  useGetStudents,
} from '../../../hooks/useGetStudents';
import { PrimaryButton } from '../../../components/PrimaryButton';
import StudentDiv from './StudentDiv';
import { useGet } from '../../../hooks/useApi';
import { EnrollmentBlocked } from '../../../shared-typings/models/enrollment-blocked.model';
import { Alert } from 'react-bootstrap';
import EditStudentModal from '../modals/EditStudentModal';
import { Student } from '../../../shared-typings/models';
import { useGetPromotionDay } from '../../../hooks/useGetPromotionDay';
import dayjs from 'dayjs';
import { qualified } from '../../../utils/studentHelpers';

const { Title, Paragraph } = Typography;

const StudentsDiv = () => {
  const navigate = useNavigate();
  const students = useGetStudents();
  const isEnrollmentBlocked = useGet<EnrollmentBlocked | null>(
    '/v1/auth/reg/is-enrollment-blocked'
  );

  const [editStudent, setEditStudent] = React.useState<Student | null>(null);
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);

  const getPromotionDay = useGetPromotionDay();
  const promotionDay = dayjs(getPromotionDay.data);
  const beforePromotionDay = getPromotionDay.data
    ? dayjs().isBefore(promotionDay)
    : false;

  const notQualified = (student: StudentWithProps) => {
    return !qualified(student);
  };

  const handleOnEdit = (student: Student) => {
    setEditStudent(student);
    setEditModalOpen(true);
  };

  const handleEditStudentModalClose = () => {
    setEditModalOpen(false);
    students.refetch();
  };

  if (students.isLoading) {
    return <Skeleton active />;
  }

  // If no student records were found, show an error component
  if (!students.data?.length) {
    return <OnError status="404" />;
  }

  const ProceedToScheduling = () => {
    if (isEnrollmentBlocked.isLoading) {
      return <Skeleton active />;
    }

    if (isEnrollmentBlocked.data) {
      return (
        <Alert variant="warning">
          <i className={'fa fa-exclamation-triangle'} /> Your account is
          currently ineligible for enrollment. Please contact us for assistance.
        </Alert>
      );
    }

    return (
      <>
        {students.data.filter(qualified).length > 0 && (
          <div style={{ textAlign: 'center', marginTop: 24 }}>
            <Paragraph>
              Does Your Student Information Look Correct?
              <br />
              If So,
            </Paragraph>
            <PrimaryButton
              onClick={() => navigate('/scheduling')}
              type="primary"
              size={'large'}
              block
            >
              Proceed To Scheduling
            </PrimaryButton>
          </div>
        )}
      </>
    );
  };

  if (students.data?.length) {
    return (
      <>
        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Title level={4}>Your Students</Title>
          </Col>
        </Row>

        <div id="studentList">
          {/** Render "qualified" students */}
          {students.data.filter(qualified).map((student, index) => (
            <StudentDiv
              key={index}
              student={student}
              qualified={true}
              beforePromotionDay={beforePromotionDay}
              onEdit={handleOnEdit}
            />
          ))}

          {/** Render "not qualified" students */}
          {students.data.filter(notQualified).map((student, index) => (
            <StudentDiv
              key={index}
              student={student}
              qualified={false}
              beforePromotionDay={beforePromotionDay}
              onEdit={handleOnEdit}
            />
          ))}
        </div>

        <ProceedToScheduling />

        {editStudent && (
          <EditStudentModal
            isOpen={editModalOpen}
            onClose={handleEditStudentModalClose}
            student={editStudent}
          />
        )}
      </>
    );
  }

  return (
    <div style={{ textAlign: 'center', marginTop: 24 }}>
      <Paragraph>
        {`Looks Like You Don't Have Any Students Yet!`}
        <br />
        To Enroll in Programs, You Will Need to Add Them in
      </Paragraph>
      <a
        href={`https://my.starsacramento.org/`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button type="primary">Your Family Account</Button>
      </a>

      <Paragraph style={{ marginTop: 16 }}>
        Maybe you just want to see what we have to offer?
        <br />
        If So,
      </Paragraph>
      <Link to="/scheduling">
        <Button>Look at Programs</Button>
      </Link>
    </div>
  );
};

export default StudentsDiv;
