// useGetEnrollingSchools.ts
import { useGet } from './useApi';
import { School } from '../shared-typings/models/school.model';

export const useGetEnrollingSchools = () => {
  return useGet<School[]>('/v1/public/reg/enrolling-schools', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
