/**
 * @file useGet.ts
 */

import { useEffect } from 'react';
import { useContextValue } from '../../Context';
import dayjs from 'dayjs';

/**
 * Hook to get the local schedule
 * @param ignoreValidityCheck - If true, the validity time check will be ignored
 * @returns The current schedule if valid, null otherwise
 */
export const useGet = (ignoreValidityCheck = false) => {
  const { state, setState } = useContextValue();
  const schedule = state.currentSelections;

  useEffect(() => {
    if (schedule) {
      const now = new Date();
      // If the schedule is expired OR there are no selections, clear it.
      if (
        (!ignoreValidityCheck && dayjs(now).isAfter(dayjs(schedule.validTo))) ||
        !schedule.selections ||
        schedule.selections.length === 0
      ) {
        setState((prevState) => ({
          ...prevState,
          currentSelections: null,
        }));
        localStorage.removeItem('currentSelections');
      }
    }
  }, [schedule, setState, ignoreValidityCheck]);

  // Return schedule only if it exists, has selections, and hasn't expired.
  if (
    schedule &&
    schedule.selections &&
    schedule.selections.length > 0 &&
    (ignoreValidityCheck || dayjs().isBefore(dayjs(schedule.validTo)))
  ) {
    return schedule;
  }
  return null;
};
