import SimplePage from '../../layout/SimplePage/SimplePage';
import Container from '../../layout/Container/Container';

import Steps from './components/Steps';
import React, { useState } from 'react';
import ConfirmPolicies from './steps/ConfirmPolicies';
import ConfirmSchedule from './steps/ConfirmSchedule';
import PayFees from './steps/PayFees';
import { usePost } from '../../hooks/useApi';
import { useGet as useGetLocalSchedule } from '../../hooks/localSchedule/useGet';
import { useParams } from 'react-router-dom';
import SignDocumentation from './steps/SignDocumentation';
import Complete from './steps/Complete';

// Define RegisterData interface to fix type errors
interface RegisterData {
  data?: {
    total?: number;
    paymentIntent?:
      | {
          id?: string;
          client_secret?: string;
        }
      | string;
    studentsSchedules?: Array<{
      currentlySchedulingId?: string;
    }>;
  };
  isLoading: boolean;
  isError: boolean;
  error?: any;
}

// Type for localSchedule.selections
type ScheduleSelections = any[];

const FinalizePage = () => {
  const params = useParams();
  const [step, setStep] = useState<number>(
    params.currentlySchedulingId === 'complete'
      ? 4
      : params.currentlySchedulingId && params.paymentIntentId
        ? 3
        : 0
  );

  // Pass true to ignore the validity check
  const localSchedule = useGetLocalSchedule(true);
  // Fix the type parameters: usePost<ResponseType, RequestType>
  const register = usePost<RegisterData, ScheduleSelections>(
    '/v1/auth/reg/register-schedule/register'
  );

  const onConfirmSchedule = () => {
    setStep(2);

    if (localSchedule) {
      register.mutate(localSchedule.selections);
    }
  };

  // Create a typed wrapper for the register mutation result that satisfies RegisterData
  const registerData: RegisterData = {
    data: register.data as RegisterData['data'],
    isLoading: register.isLoading,
    isError: register.isError,
    error: register.error,
  };

  return (
    <SimplePage>
      <Container>
        <Steps current={step} />
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          {step === 0 && <ConfirmPolicies onNextStep={() => setStep(1)} />}
          {step === 1 && <ConfirmSchedule onNextStep={onConfirmSchedule} />}
          {step === 2 && (
            <PayFees register={registerData} onGoBack={() => setStep(1)} />
          )}
          {step === 3 && params.paymentIntentId && (
            <SignDocumentation
              paymentIntentId={params.paymentIntentId}
              onGoBack={() => setStep(2)}
            />
          )}
          {step === 4 && params.paymentIntentId && (
            <Complete paymentIntentId={params.paymentIntentId} />
          )}
        </div>
      </Container>
    </SimplePage>
  );
};

export default FinalizePage;
