import React from 'react';
import { Container } from 'react-bootstrap';
import { Button, Result, Skeleton, Alert } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import OnError from '../../../../components/OnError';
import NextStepButton from '../../components/NextStepButton';
import PaymentForm from './PaymentForm';
import { useGet as useGetLocalSchedule } from '../../../../hooks/localSchedule/useGet';
import { useClear as useClearLocalSchedule } from '../../../../hooks/localSchedule/useClear';

// Define interface for register props
interface RegisterData {
  data?: {
    total?: number;
    paymentIntent?:
      | {
          id?: string;
          client_secret?: string;
        }
      | string;
    studentsSchedules?: Array<{
      currentlySchedulingId?: string;
    }>;
  };
  isLoading: boolean;
  isError: boolean;
  error?: any;
}

const stripePromise = loadStripe('pk_test_wCDreqtYqBY1ECHM6B932Ob1');

/**
 * PayFees component
 * @param register - the result of your registration API call (React Query, etc.)
 * @param onGoBack - function to go back in your flow
 */
export default function PayFees({
  register,
  onGoBack,
}: {
  register: RegisterData;
  onGoBack: () => void;
}) {
  const localSchedule = useGetLocalSchedule();
  const clearLocalSchedule = useClearLocalSchedule();

  // The backend sets 'amount' in 'register.data?.return?.amount'
  const amount = +(register.data?.total || 0);

  // The backend sets 'paymentIntent' in 'register.data?.return?.paymentIntent'
  // Safe extraction of client_secret, handling both object and string cases
  const clientSecret =
    typeof register.data?.paymentIntent === 'object'
      ? register.data?.paymentIntent?.client_secret || ''
      : '';

  const paymentIntentId =
    typeof register.data?.paymentIntent === 'string'
      ? register.data?.paymentIntent
      : register.data?.paymentIntent?.id || '';

  const currentlySchedulingId =
    register.data?.studentsSchedules?.[0]?.currentlySchedulingId || '';

  const handleOnGoBack = () => {
    onGoBack();
  };

  const handleOnNextStep = () => {
    // Clear localSchedule data on success
    if (
      localSchedule &&
      localSchedule.selections &&
      localSchedule.selections.length > 0
    ) {
      // Completely clear the localSchedule
      clearLocalSchedule();
    }
    window.location.href = `${window.location.origin}/finalize/${currentlySchedulingId}/${paymentIntentId}?redirect_status=succeeded`;
  };

  // --- Loading ---
  if (register.isLoading) {
    return (
      <Container>
        <div role="progressbar">
          <Skeleton active />
        </div>
      </Container>
    );
  }

  // --- Error Handling ---
  if (register.isError) {
    // Instead of using getDataFromApiError directly, we manually extract the error data
    const errorMessage =
      register.error?.response?.data?.message || 'Something went wrong';

    return (
      <Container>
        <OnError
          title="Error"
          status={500}
          subTitle={errorMessage}
          extra={
            <Button type="default" onClick={handleOnGoBack}>
              Go Back
            </Button>
          }
        />
      </Container>
    );
  }

  // --- No Payment Required ---
  if (!amount) {
    return (
      <Container>
        <Result
          status="success"
          title="Success"
          subTitle="No payment required"
        />
        <NextStepButton disabled={false} onClick={handleOnNextStep} />
      </Container>
    );
  }

  // --- Payment Required ---
  // If the backend returned a valid clientSecret, render the PaymentForm.
  if (!clientSecret) {
    console.error('PaymentIntent details:', {
      paymentIntent: register.data?.paymentIntent,
      isString: typeof register.data?.paymentIntent === 'string',
      isObject: typeof register.data?.paymentIntent === 'object',
      hasClientSecret:
        typeof register.data?.paymentIntent === 'object' &&
        'client_secret' in (register.data?.paymentIntent || {}),
    });

    return (
      <Container>
        <Alert
          message="Missing client secret"
          description={
            <>
              <p>We could not retrieve the Stripe client secret.</p>
              <p>PaymentIntent ID: {paymentIntentId || 'Not available'}</p>
            </>
          }
          type="error"
          showIcon
        />
        <Button onClick={handleOnGoBack} style={{ marginTop: 16 }}>
          Go Back
        </Button>
      </Container>
    );
  }

  // We re-mount Elements with the clientSecret (necessary for PaymentElement usage).
  const appearance = { theme: 'stripe' as const };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Container>
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm
          paymentIntentId={paymentIntentId}
          currentlySchedulingId={currentlySchedulingId}
          amount={amount}
        />
      </Elements>
    </Container>
  );
}
