// useGetConcurrentEnrollmentDiscounts.ts
import { useGet } from './useApi';
// import { ConcurrentEnrollmentDiscount } from '../shared-typings/models/concurrent-enrollment-discount.model';

/**
 * Se o back-end retorna um objeto: { [scheduleId: string]: ConcurrentEnrollmentDiscount[] },
 * tipar como Record<string, ConcurrentEnrollmentDiscount[]>.
 */
export const useGetConcurrentEnrollmentDiscounts = () => {
  return useGet<Record<string, any[]>>(
    '/v1/public/reg/concurrent-enrollment-discounts',
    {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    }
  );
};
