import { useCallback } from 'react';
import { useContextValue } from '../../Context';

export function useRemove(callback?: (currentSelections: any) => void) {
  const { setState } = useContextValue();

  const remove = useCallback(
    (index: number) => {
      setState((prevState: any) => {
        if (prevState.currentSelections) {
          const { selections, ...rest } = prevState.currentSelections;
          const newSelections = selections.filter(
            (_: any, i: number) => i !== index
          );

          if (newSelections.length > 0) {
            const newState = {
              ...prevState,
              currentSelections: {
                ...rest,
                selections: newSelections,
              },
            };

            localStorage.setItem('currentSelections', JSON.stringify(newState));

            if (callback) {
              callback(newState.currentSelections);
            }
            return newState;
          } else {
            localStorage.removeItem('currentSelections');

            if (callback) {
              callback(null);
            }

            return {
              ...prevState,
              currentSelections: null,
            };
          }
        }

        localStorage.removeItem('currentSelections');
        return prevState;
      });
    },
    [setState, callback]
  );

  return remove;
}
