// useGetProgramTypes.ts
import { useGet } from './useApi';
import { ProgramType } from '../shared-typings/models/program-type.model';

export const useGetProgramTypes = () => {
  return useGet<ProgramType[]>('/v1/public/reg/program-types', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
