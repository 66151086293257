import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Alert, Button } from 'antd';

/**
 * PaymentForm component: renders Stripe's PaymentElement and handles confirmation
 */
export default function PaymentForm({
  paymentIntentId,
  currentlySchedulingId,
  amount,
}: {
  paymentIntentId: string;
  currentlySchedulingId: string;
  amount: number;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [isPaying, setIsPaying] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsPaying(true);

    // Confirm the payment: Stripe will redirect upon success/failure
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Adjust your return URL to your relevant finalize route
        return_url: `${window.location.origin}/finalize/${currentlySchedulingId}/${paymentIntentId}`,
      },
    });

    if (error) {
      // Show error to your customer
      setMessage(error.message || 'An unexpected error occurred.');
      setIsPaying(false);
    }
  };

  return (
    <>
      {message && (
        <Alert
          style={{ marginBottom: 20 }}
          message="Payment Error"
          description={message}
          type="error"
          showIcon
        />
      )}
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          block={true}
          size={'large'}
          type="primary"
          htmlType="submit"
          loading={isPaying}
          disabled={!stripe || !elements || isPaying}
          style={{ marginTop: 20 }}
        >
          <table className={'w-100'}>
            <tr>
              <td style={{ width: '30px' }}></td>
              <td>
                Pay{' '}
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(Number(amount))}
              </td>
              <td className={'text-right'} style={{ width: '30px' }}>
                <i className={'fa fa-chevron-right'}></i>
              </td>
            </tr>
          </table>
        </Button>
      </form>
    </>
  );
}
