import { useCallback } from 'react';
import { useContextValue } from '../../Context';

/**
 * Hook to completely clear the localSchedule
 * @param callback Optional function to be called after clearing
 * @returns Function to clear the localSchedule
 */
export function useClear(callback?: (currentSelections: null) => void) {
  const { setState } = useContextValue();

  const clear = useCallback(() => {
    setState((prevState: any) => {
      // Remove from localStorage
      localStorage.removeItem('currentSelections');

      if (callback) {
        callback(null);
      }

      // Return state with currentSelections as null
      return {
        ...prevState,
        currentSelections: null,
      };
    });
  }, [setState, callback]);

  return clear;
}
