// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loginDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Define a altura mínima da div como 100% da altura da viewport */
  background-size: cover; /* Para garantir que a imagem de fundo cubra toda a área */
  background-position: center;
}

#star-logo {
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB,EAAE,kEAAkE;EACrF,sBAAsB,EAAE,0DAA0D;EAClF,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["#loginDiv {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh; /* Define a altura mínima da div como 100% da altura da viewport */\n  background-size: cover; /* Para garantir que a imagem de fundo cubra toda a área */\n  background-position: center;\n}\n\n#star-logo {\n  width: 100%;\n  max-width: 250px;\n  margin-left: auto;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
