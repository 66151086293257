import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
  QueryClient,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query';
import api from '../services/apiService';
import { AxiosError } from 'axios';
import qs from 'qs';
import { notification } from 'antd';

/**
 * Interface representing an API response.
 * @template T - The type of the data in the response.
 */
interface ApiResponse<T> {
  return: T;
  message: string;
  code: number;
  error: boolean;
  redirect_to: string | null;
}

/**
 * Interface representing an API error.
 */
class ApiError extends Error {
  message: string;
  code: number;
  redirect_to: string | null;

  constructor(message: string, code: number, redirect_to: string | null) {
    super(message);
    this.message = message;
    this.code = code;
    this.redirect_to = redirect_to;
  }
}

const queryClient = new QueryClient();

/**
 * Interface representing request options.
 */
interface RequestOptions {
  params?: Record<string, any>;
  headers?: Record<string, string>;
}

/**
 * Interface representing options for useGet hook.
 */
export interface UseGetOptions extends UseQueryOptions<any, AxiosError> {
  queryKey?: QueryKey;
  params?: Record<string, any>;
  headers?: Record<string, string>;
}

/**
 * Interface representing options for mutation hooks.
 * Extends UseMutationOptions to include headers.
 */
interface UseMutationWithHeadersOptions<T, D>
  extends UseMutationOptions<T, AxiosError, D> {
  headers?: Record<string, string>;
}

/**
 * Generates the authorization header from local storage.
 * @returns The authorization header as a string or null if not available.
 */
export const generateAuthorizationHeader = (): string | null => {
  const auth = localStorage.getItem('auth');
  if (!auth) return null;
  const authObject = JSON.parse(auth);
  const utf8AuthString = new TextEncoder().encode(
    `${authObject.username}:${authObject.password}`
  );
  // @ts-ignore
  const base64AuthString = btoa(String.fromCharCode(...utf8AuthString));
  return `Basic ${base64AuthString}`;
};

/**
 * Merges provided headers with the authorization header.
 * @param headers - Optional headers to merge.
 * @returns A merged headers object.
 */
const getHeaders = (
  headers?: Record<string, string>
): Record<string, string> => {
  const authHeader = generateAuthorizationHeader();
  return {
    ...headers,
    ...(authHeader ? { Authorization: authHeader } : {}),
  };
};

/**
 * Fetches data from the given endpoint.
 * @template T - The type of the data to fetch.
 * @param endpoint - The API endpoint to fetch data from.
 * @param options - Optional request options.
 * @returns The fetched data.
 */
const fetcher = async <T>(
  endpoint: string,
  options?: RequestOptions
): Promise<T> => {
  const queryString = options?.params ? `?${qs.stringify(options.params)}` : '';
  const response = await api.get<ApiResponse<T>>(`${endpoint}${queryString}`, {
    headers: getHeaders(options?.headers),
  });
  if (response.data.error) {
    throw new ApiError(
      response.data.message,
      response.data.code,
      response.data.redirect_to
    );
  }
  return response.data.return;
};

/**
 * Handles HTTP requests.
 * @template T - The type of the data to return.
 * @template D - The type of the data to send.
 * @param method - The HTTP method (post, patch, put, delete).
 * @param endpoint - The API endpoint.
 * @param data - The data to send.
 * @param options - Optional request options.
 * @returns The response data.
 */
const requestHandler = async <T, D>(
  method: 'post' | 'patch' | 'put' | 'delete',
  endpoint: string,
  data?: D,
  options?: RequestOptions
): Promise<T> => {
  const config = { headers: getHeaders(options?.headers) };

  const response =
    method === 'delete'
      ? await api.delete<ApiResponse<T>>(endpoint, config)
      : await api[method]<ApiResponse<T>>(endpoint, data, config);

  if (response.data.error) {
    throw new ApiError(
      response.data.message,
      response.data.code,
      response.data.redirect_to
    );
  }
  return response.data.return;
};

/**
 * React hook for performing a GET request with authorization.
 * @template T - The type of the data to fetch.
 * @param endpoint - The API endpoint.
 * @param options - Optional query options.
 * @returns The query result.
 */
const useQueryWithAuth = <T>(
  endpoint: string,
  options?: UseGetOptions
): UseQueryResult<T, AxiosError> => {
  const queryKey = options?.queryKey || [endpoint, options?.params];
  return useQuery<T, AxiosError>(
    queryKey,
    () =>
      fetcher<T>(endpoint, {
        params: options?.params,
        headers: options?.headers,
      }),
    {
      ...options,
      retry: (failureCount, error) => {
        return (
          (error.response?.status || 0) >= 500 &&
          (error.response?.status || 0) < 600
        );
      },
    }
  );
};

/**
 * React hook for performing a mutation request with authorization.
 * @template T - The type of the response data.
 * @template D - The type of the data to send.
 * @param method - The HTTP method (post, patch, put, delete).
 * @param endpoint - The API endpoint.
 * @param options - Optional mutation options.
 * @returns The mutation result.
 */
const useMutationWithAuth = <T, D>(
  method: 'post' | 'patch' | 'put' | 'delete',
  endpoint: string,
  options?: UseMutationWithHeadersOptions<T, D>
): UseMutationResult<T, AxiosError, D> => {
  const defaultOptions: UseMutationWithHeadersOptions<T, D> = {
    onSuccess: () => queryClient.invalidateQueries(),
    retry: (failureCount, error) => {
      return (
        (error.response?.status || 0) >= 500 &&
        (error.response?.status || 0) < 600
      );
    },
  };
  const mergedOptions = { ...defaultOptions, ...options };
  return useMutation<T, AxiosError, D>(
    (data: D) =>
      requestHandler<T, D>(method, endpoint, data, {
        headers: mergedOptions.headers,
      }),
    mergedOptions
  );
};

export const useGet = useQueryWithAuth;

export const usePost = <T, D>(
  endpoint: string,
  options?: UseMutationWithHeadersOptions<T, D>
) => useMutationWithAuth('post', endpoint, options);

export const usePatch = <T, D>(
  endpoint: string,
  options?: UseMutationWithHeadersOptions<T, D>
) => useMutationWithAuth('patch', endpoint, options);

export const usePut = <T, D>(
  endpoint: string,
  options?: UseMutationWithHeadersOptions<T, D>
) => useMutationWithAuth('put', endpoint, options);

export const useDelete = <T>(
  endpoint: string,
  options?: UseMutationWithHeadersOptions<T, void>
) => useMutationWithAuth('delete', endpoint, options);

/**
 * Retrieves the error message from a mutation error object.
 *
 * @param {any} mutationError - The error object from a mutation.
 * @returns {string | null} - The error message, or null if not available.
 */
export const getErrorMessage = (mutationError: any): string | null => {
  return mutationError?.response?.data?.message || null;
};

/**
 * Displays a notification based on the mutation status.
 *
 * @param {any} mutation - The mutation object containing the status and error.
 * @param {Object} [options] - Optional configuration for success and error messages.
 * @param {Object} [options.success] - Success message configuration.
 * @param {string} [options.success.title='Success'] - Title for the success message.
 * @param {string} [options.success.description='Operation completed successfully.'] - Description for the success message.
 * @param {Object} [options.error] - Error message configuration.
 * @param {string} [options.error.title='Error'] - Title for the error message.
 * @param {string} [options.error.description='An error occurred. Please try again.'] - Description for the error message.
 */
export const mutationNotification = (
  mutation: any,
  options: {
    success?: { title?: string; description?: string };
    error?: { title?: string; description?: string };
  } = {
    success: {
      title: 'Success',
      description: 'Operation completed successfully.',
    },
    error: {
      title: 'Error',
      description: 'An error occurred. Please try again.',
    },
  }
) => {
  if (mutation.isSuccess) {
    notification.success({
      message: options.success?.title || 'Success',
      description:
        options.success?.description || 'Operation completed successfully.',
      placement: 'topRight',
    });
  }

  if (mutation.isError) {
    notification.error({
      message: options.error?.title || 'Error',
      description:
        getErrorMessage(mutation.error) ||
        options.error?.description ||
        'An error occurred. Please try again.',
      placement: 'topRight',
    });
  }
};

export const getDataFromApiError = (
  apiCall: UseMutationResult<any, AxiosError>
): any | null => {
  return apiCall.error?.response?.data || null;
};
