import { FC, ReactNode } from 'react';

// Padding Top Components
export const Pt1: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pt-1">{children}</div>
);
export const Pt2: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pt-2">{children}</div>
);
export const Pt3: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pt-3">{children}</div>
);
export const Pt4: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pt-4">{children}</div>
);
export const Pt5: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pt-5">{children}</div>
);

// Padding Bottom Components
export const Pb1: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pb-1">{children}</div>
);
export const Pb2: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pb-2">{children}</div>
);
export const Pb3: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pb-3">{children}</div>
);
export const Pb4: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pb-4">{children}</div>
);
export const Pb5: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pb-5">{children}</div>
);

// Padding Left Components
export const Pl1: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pl-1">{children}</div>
);
export const Pl2: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pl-2">{children}</div>
);
export const Pl3: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pl-3">{children}</div>
);
export const Pl4: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pl-4">{children}</div>
);
export const Pl5: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pl-5">{children}</div>
);

// Padding Right Components
export const Pr1: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pr-1">{children}</div>
);
export const Pr2: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pr-2">{children}</div>
);
export const Pr3: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pr-3">{children}</div>
);
export const Pr4: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pr-4">{children}</div>
);
export const Pr5: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="pr-5">{children}</div>
);
