import React, { FC } from 'react';
import { Button, Card } from 'antd';

const RegisterDiv: FC = () => {
  const goToRegister = (ev: any) => {
    ev.preventDefault();
    window.location.href = 'https://accounts.starsacramento.org/';
  };

  return (
    <Card className={'text-center mt-3'}>
      <p style={{ fontSize: '11px' }} className={'pb-3'}>
        <b>{`Don't Have An Account Yet?`}</b>
        <br />
        {`That's OK! Feel Free To Just`}
      </p>

      <div className={'pb-3'}>
        <Button
          href={'/scheduling'}
          type="primary"
          style={{ backgroundColor: '#703895' }}
          block
          size={'large'}
        >
          Look At Programs
        </Button>
      </div>

      <div className={'pb-3'}>
        <p>Or</p>
      </div>

      <Button
        onClick={goToRegister}
        type="primary"
        style={{ backgroundColor: '#703895' }}
        block
        size={'large'}
      >
        Create An Account
      </Button>
    </Card>
  );
};

export default RegisterDiv;
