import { useGet } from '../../../../hooks/useApi';
import { Container } from 'react-bootstrap';
import OnError from '../../../../components/OnError';
import { Button, Skeleton, Progress, Typography, Alert } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { DocusealForm } from '@docuseal/react';
import { useGet as useGetLocalSchedule } from '../../../../hooks/localSchedule/useGet';
import { useClear as useClearLocalSchedule } from '../../../../hooks/localSchedule/useClear';
import NextStepButton from '../../components/NextStepButton';
import Complete from '../Complete';
import { Pb3 } from '../../../../components/P';

const { Title, Text } = Typography;

interface ContractData {
  studentId: number;
  studentName: string;
  documentId: string;
  slug: string;
  completed: boolean;
}

interface SignDocumentationProps {
  paymentIntentId: string;
  onGoBack: () => void;
}

const SignDocumentation = ({
  paymentIntentId,
  onGoBack,
}: SignDocumentationProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectStatus = urlParams.get('redirect_status') || 'succeeded';
  const localSchedule = useGetLocalSchedule();
  const clearLocalSchedule = useClearLocalSchedule();

  // State to track the current contract index and completion
  const [currentContractIndex, setCurrentContractIndex] = useState<number>(0);
  const [allContractsCompleted, setAllContractsCompleted] =
    useState<boolean>(false);
  // This is a guard to prevent repeated calls from Docuseal's onComplete
  const [formJustCompleted, setFormJustCompleted] = useState<boolean>(false);

  // Retrieve all contract slugs for this paymentIntent
  const getContractSlugs = useGet<ContractData[]>(
    `/v1/auth/reg/register-schedule/contract-slugs`
  );

  // Determine which contract to show first by finding the first incomplete one
  useEffect(() => {
    if (getContractSlugs.data && getContractSlugs.data.length > 0) {
      const incompleteIndex = getContractSlugs.data.findIndex(
        (contract) => !contract.completed
      );
      if (incompleteIndex !== -1) {
        setCurrentContractIndex(incompleteIndex);
      } else {
        // If all contracts are already complete
        setAllContractsCompleted(true);
      }
    }
  }, [getContractSlugs.data]);

  // Clear localSchedule once the documentation (and scheduling) has loaded successfully
  useEffect(() => {
    if (
      !getContractSlugs.isLoading &&
      !getContractSlugs.isError &&
      redirectStatus === 'succeeded' &&
      localSchedule
    ) {
      clearLocalSchedule();
      console.log('LocalSchedule was cleared during document signing step');
    }
  }, [
    getContractSlugs.isLoading,
    getContractSlugs.isError,
    redirectStatus,
    localSchedule,
    clearLocalSchedule,
  ]);

  const handleOnGoBack = () => {
    onGoBack();
  };

  // Reset the form-completion guard each time we successfully move to a new contract
  useEffect(() => {
    setFormJustCompleted(false);
  }, [currentContractIndex]);

  // Callback triggered once a Docuseal form is completed
  const handleFormCompleted = useCallback(async () => {
    // If we’ve already handled a completion event for this same contract, skip
    if (formJustCompleted) return;
    setFormJustCompleted(true);

    console.log('Form completed, refetching contract status...');
    try {
      const response = await getContractSlugs.refetch();
      const updatedContracts = response.data || [];

      console.log('Contract data refreshed:', updatedContracts);

      if (updatedContracts.length === 0) {
        console.error('No contracts found after refresh');
        setAllContractsCompleted(true);
        return;
      }

      // Find the first incomplete contract’s index
      const nextIncompleteIndex = updatedContracts.findIndex(
        (contract) => !contract.completed
      );

      if (nextIncompleteIndex === -1) {
        // All are complete
        setAllContractsCompleted(true);
      } else {
        // Move to the next incomplete
        setCurrentContractIndex(nextIncompleteIndex);
      }
    } catch (error) {
      console.error('Error refreshing contract data:', error);
      // On error, use local fallback and attempt the same logic
      const fallbackContracts = getContractSlugs.data || [];
      const nextIncompleteIndex = fallbackContracts.findIndex(
        (contract) => !contract.completed
      );
      if (nextIncompleteIndex === -1) {
        setAllContractsCompleted(true);
      } else {
        setCurrentContractIndex(nextIncompleteIndex);
      }
    }
  }, [getContractSlugs, formJustCompleted]);

  // If payment didn't succeed
  if (redirectStatus !== 'succeeded') {
    return (
      <Container>
        <OnError
          title="Error"
          status={500}
          subTitle="Something went wrong with your payment"
          extra={
            <Button type="default" onClick={handleOnGoBack}>
              Go Back
            </Button>
          }
        />
      </Container>
    );
  }

  // While loading contract data
  if (getContractSlugs.isLoading) {
    return (
      <Container>
        <div role="progressbar">
          <Skeleton active />
        </div>
      </Container>
    );
  }

  // If there's an error loading contract data
  if (getContractSlugs.isError) {
    return (
      <Container>
        <OnError
          title="Error Loading Documents"
          status={500}
          subTitle="Unable to load contract documents"
          extra={
            <Button type="default" onClick={handleOnGoBack}>
              Go Back
            </Button>
          }
        />
      </Container>
    );
  }

  // Contracts array
  const contracts = getContractSlugs.data || [];

  // If there are no contracts
  if (contracts.length === 0) {
    return (
      <Container>
        <Pb3>
          <Alert
            message="No Contracts Found"
            description="No contracts were found for this registration. Please contact support if you believe this is an error."
            type="warning"
            showIcon
          />
        </Pb3>
        <NextStepButton
          text="Return to Dashboard"
          disabled={false}
          onClick={() => (window.location.href = '/scheduling')}
        />
      </Container>
    );
  }

  // If all contracts have been completed
  if (allContractsCompleted) {
    return (
      <Container>
        <Pb3>
          <Alert
            message="All Contracts Signed"
            description="Thank you! All contracts have been successfully signed and submitted."
            type="success"
            showIcon
          />
        </Pb3>
        <Complete paymentIntentId={paymentIntentId} />
      </Container>
    );
  }

  const currentContract = contracts[currentContractIndex];

  // Safety check to ensure a current contract exists
  if (!currentContract) {
    return (
      <Container>
        <div role="progressbar">
          <Skeleton active />
          <p>Loading contract information...</p>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div style={{ marginBottom: 20 }}>
        <Progress
          percent={Math.round(
            ((currentContractIndex + 1) / contracts.length) * 100
          )}
          status="active"
        />
        <Title level={4} style={{ marginTop: 16 }}>
          Contract {currentContractIndex + 1}/{contracts.length} -{' '}
          {currentContract.studentName}
        </Title>
        <Text type="secondary">
          Please review and sign this contract for {currentContract.studentName}
          .
        </Text>
      </div>

      <div
        style={{
          maxHeight: '800px',
          overflow: 'auto',
          border: '1px solid #f0f0f0',
          borderRadius: '4px',
        }}
      >
        {currentContract.slug && (
          <DocusealForm
            key={currentContract.slug}
            src={`https://docuseal.com/s/${currentContract.slug}`}
            onComplete={handleFormCompleted}
          />
        )}
      </div>
    </Container>
  );
};

export default SignDocumentation;
