import Container from '../../../../layout/Container/Container';
import { Button, Result, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useGet } from '../../../../hooks/useApi';
import OnError from '../../../../components/OnError';
import { useGet as useGetLocalSchedule } from '../../../../hooks/localSchedule/useGet';
import { useClear as useClearLocalSchedule } from '../../../../hooks/localSchedule/useClear';
import { useEffect } from 'react';

interface CompleteProps {
  paymentIntentId: string;
}

const Complete = ({ paymentIntentId }: CompleteProps) => {
  const navigate = useNavigate();
  const localSchedule = useGetLocalSchedule();
  const clearLocalSchedule = useClearLocalSchedule();

  const useConfirm = useGet(
    `/v1/auth/reg/register-schedule/complete/${paymentIntentId}`
  );

  // Clear the localSchedule when confirmation is successful
  useEffect(() => {
    if (!useConfirm.isLoading && !useConfirm.isError && localSchedule) {
      // Completely clear the localSchedule
      clearLocalSchedule();
      console.log(
        'LocalSchedule was completely cleared after successful confirmation'
      );
    }
  }, [
    useConfirm.isLoading,
    useConfirm.isError,
    localSchedule,
    clearLocalSchedule,
  ]);

  if (useConfirm.isError) {
    return (
      <OnError
        title={`Error`}
        subTitle="Error confirming schedule"
        extra={
          <Button type="dashed" onClick={() => navigate('/scheduling')}>
            Go Back
          </Button>
        }
        status={404}
      />
    );
  }

  return (
    <Container>
      {useConfirm.isLoading ? (
        <div role="progressbar">
          <Skeleton active />
        </div>
      ) : (
        <Result
          status="success"
          title="Success"
          subTitle="Your schedule is complete"
          extra={
            <Button type="default" onClick={() => navigate('/')}>
              Back To Home Page
            </Button>
          }
        />
      )}
    </Container>
  );
};

export default Complete;
