import { FC, ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

interface ContainerProps {
  children: ReactNode;
}

const Container: FC<ContainerProps> = ({ children }) => {
  return (
    <Row>
      <Col md={2}></Col>
      <Col md={8}>{children}</Col>
    </Row>
  );
};

export default Container;
