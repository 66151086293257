// useGetSchools.ts
import { useGet } from './useApi';

export const useGetPromotionDay = () => {
  return useGet<string>('/v1/public/reg/globals/promotion-day', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
