import { FC, useEffect, useState, MouseEvent } from 'react';
import { Button, Card, Skeleton } from 'antd';
import { useFetchUser } from '../../hooks/useFetchUser';
import SimplePage from '../../layout/SimplePage/SimplePage';
import Container from '../../layout/Container/Container';
import ProgramList from './components/ProgramList';
import { Pt4 } from '../../components/P';
import constants from '../../constants';
import OnEmpty from '../../components/OnEmpty';
import SetupModal, { OnCloseArgs } from './modals/SetupModal';
import FilterTable from './components/FilterTable';
import dayjs, { Dayjs } from 'dayjs';
import { ProgramListing } from '../../models/program-listing.model';
import { EnrollingSchool } from '../../models/enrolling-school';
import { useGetEnrollingSchools } from '../../hooks/useGetEnrollingSchools';
import { useGetProgramListings } from '../../hooks/useGetProgramListings';
import { useGetProgramTypes } from '../../hooks/useGetProgramTypes';
import IncomingKModal from './modals/IncomingKModal';
import SavedSchedulling from './components/SavedSchedulling';

// Define the structure of a program
type Program = ProgramListing;

const SchedulingPage: FC = () => {
  const user = useFetchUser();

  const programTypes = useGetProgramTypes();
  const enrollingSchools = useGetEnrollingSchools();
  const programListings = useGetProgramListings();

  const loading =
    user.isLoading ||
    programTypes.isLoading ||
    enrollingSchools.isLoading ||
    programListings.isLoading;

  const [programs, setPrograms] = useState<string[]>([]);
  const [data, setData] = useState<any>({});
  const [search, setSearch] = useState<string>('');

  const [selectedProgramTypes, setSelectedProgramTypes] = useState<number[]>(
    []
  );
  const [selectedRegions, setSelectedRegions] = useState<number[] | ['any']>([
    'any',
  ]);
  const [selectedSchools, setSelectedSchools] = useState<number[]>([]);

  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    null
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);

  const [modalsLoaded, setModalsLoaded] = useState<boolean>(false);
  const [setupModalOpen, setSetupModalOpen] = useState<boolean>(false);
  const [incomingKModalOpen, setIncomingKModalOpen] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleFilters = (ev: MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    setShowFilters(!showFilters);
  };

  const handleIncomingKModalClose = () => {
    setIncomingKModalOpen(false);
    setSetupModalOpen(true);
  };

  const handleFilterModalClose = (args: OnCloseArgs) => {
    setSelectedProgramTypes(args?.selectedProgramTypes || []);
    setSelectedRegions(args?.selectedRegions || ['any']);
    setSetupModalOpen(false);
  };

  const getSchools = (): EnrollingSchool[] => {
    const e = enrollingSchools?.data || [];
    return e.filter((item) =>
      selectedRegions.some(
        (region) =>
          region === 'any' || (item.regions && item.regions.includes(region))
      )
    );
  };

  const resetFilters = () => {
    setSelectedProgramTypes([]);
    setSelectedRegions(['any']);
    setSelectedSchools([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSearch('');
  };

  const filterAndSetPrograms = (groupedPrograms: any): void => {
    const filteredPrograms: any = {};

    const isBetweenDates = (program: Program): boolean => {
      if (!selectedStartDate && !selectedEndDate) {
        return true;
      }

      if (!selectedEndDate) {
        if (dayjs(program.startDate).isAfter(selectedStartDate)) {
          return true;
        }
      }

      if (!selectedStartDate) {
        if (dayjs(program.endDate).isBefore(selectedEndDate)) {
          return true;
        }
      }

      if (
        dayjs(program.startDate).isAfter(selectedStartDate) &&
        dayjs(program.endDate).isBefore(selectedEndDate)
      ) {
        return true;
      }

      return false;
    };

    const isInSelectedProgramType = (program: Program): boolean => {
      if (!selectedProgramTypes.length) {
        return true;
      }

      return !!(
        program.programTypeId &&
        selectedProgramTypes.includes(program.programTypeId)
      );
    };

    const isInSelectedRegion = (program: Program): boolean => {
      const schools = getSchools();
      return !!schools.find((school) => school.id === program.schoolId);
    };

    const inSelectedSchools = (program: Program): boolean => {
      return (
        !selectedSchools.length || selectedSchools.includes(program.schoolId!)
      );
    };

    const isInSearch = (program: Program): boolean => {
      return (
        !search || program.title.toLowerCase().includes(search.toLowerCase())
      );
    };

    for (const index in groupedPrograms) {
      filteredPrograms[index] = [];

      for (const program of groupedPrograms[index]) {
        if (!isInSelectedProgramType(program)) continue;
        if (!isInSelectedRegion(program)) continue;
        if (!isInSearch(program)) continue;
        if (!inSelectedSchools(program)) continue;
        if (!isBetweenDates(program)) continue;

        filteredPrograms[index].push(program);
      }
    }

    setData(filteredPrograms);
    setPrograms(
      Object.keys(programListings.data || {}).filter(
        (program) => filteredPrograms[program].length > 0
      )
    );
  };

  useEffect(() => {
    if (programListings.data) {
      filterAndSetPrograms(programListings.data);
    }
  }, [
    programListings.data,
    search,
    selectedProgramTypes,
    selectedRegions,
    selectedSchools,
    selectedStartDate,
    selectedEndDate,
  ]);

  useEffect(() => {
    if (modalsLoaded) {
      return;
    }

    if (user.data?.id === -1) {
      // Unit Tests

      setIncomingKModalOpen(false);
      setSetupModalOpen(false);
      setModalsLoaded(true);

      return;
    }

    const cache = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;

    if (user.data?.id || cache?.id) {
      setIncomingKModalOpen(true);
      setSetupModalOpen(false);
    } else {
      setIncomingKModalOpen(false);
      setSetupModalOpen(true);
    }

    setModalsLoaded(true);
  }, [user.isSuccess]);

  const colors: Record<string, string> = {
    Galaxy: constants.galaxyColorLight,
    Preschool: constants.preschoolColorLight,
    'Summer Camp': constants.campsColorLight,
    Nova: constants.novaColorLight,
    'ELO-P': constants.galaxyColorLight,
  };

  if (loading) {
    return (
      <SimplePage>
        <Container>
          <Skeleton active={true} />
        </Container>
      </SimplePage>
    );
  }

  if (incomingKModalOpen || setupModalOpen) {
    return (
      <SimplePage>
        <Container>
          <IncomingKModal
            isOpen={incomingKModalOpen}
            onClose={handleIncomingKModalClose}
          />

          <SetupModal
            isOpen={setupModalOpen}
            onClose={handleFilterModalClose}
          />
        </Container>
      </SimplePage>
    );
  }

  return (
    <SimplePage>
      <Container>
        <SavedSchedulling />

        <div className={'text-right'}>
          <Button
            id={'toggle-filters-button'}
            data-testid="toggle-filters-button"
            type="dashed"
            href={'#'}
            onClick={toggleFilters}
          >
            <i className="fa-solid fa-bars"></i>
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>

          <Button
            id={'reset-filters-button'}
            data-testid="reset-filters-button"
            type="dashed"
            href={'#'}
            onClick={resetFilters}
            className={'ml-3'}
          >
            <i className="fa-solid fa-times"></i>
            Reset Filters
          </Button>
        </div>

        {showFilters && (
          <FilterTable
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            selectedProgramTypes={selectedProgramTypes}
            setSelectedProgramTypes={setSelectedProgramTypes}
            programTypes={programTypes?.data || []}
            selectedSchools={selectedSchools}
            setSelectedSchools={setSelectedSchools}
            schools={getSchools()}
            search={search}
            setSearch={setSearch}
          />
        )}

        <Pt4>
          <hr />
        </Pt4>

        {!programs.length && (
          <Pt4>
            <Card data-testid={'no-programs-found'}>
              <OnEmpty title={'No programs found'} />
            </Card>
          </Pt4>
        )}

        {programs.map((program) => (
          <div key={`ProgramList-Pt3-${program}`} data-testid="program-list">
            <Pt4>
              <ProgramList
                title={program}
                programs={data[program] || []}
                backgroundColor={colors[program] || ''}
              />
            </Pt4>
          </div>
        ))}
      </Container>
    </SimplePage>
  );
};

export default SchedulingPage;
