import React, { FC } from 'react';
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import { useGetPromotionDay } from '../../../hooks/useGetPromotionDay';
import { useGetStudents } from '../../../hooks/useGetStudents';
import { qualified } from '../../../utils/studentHelpers';
import { usePut } from '../../../hooks/useApi';
import { Student } from '../../../shared-typings/models';
import { Pt3 } from '../../../components/P';
import Swal from 'sweetalert2';

const enrollmentPeriod = {
  currentYear: 'currentYear',
  earlyEnrollment: 'earlyEnrollment',
  lateEnrollment: 'lateEnrollment',
};

const StudentDiv: FC<{ student: Student; onSave: () => any }> = ({
  student,
  onSave,
}) => {
  const put = usePut('/v1/auth/reg/student/' + student.id);

  const setGrade = (grade: number) => {
    put.mutate(
      { gradeId: grade },
      {
        onSuccess: () => onSave(),
        onError: () => {
          onSave();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        },
      }
    );
  };

  return (
    <tr>
      <td style={{ paddingRight: '1rem' }}>
        {student.firstName} {student.lastName}
      </td>
      {put.isLoading ? (
        <td className={'text-right'}>Saving...</td>
      ) : (
        <td className={'text-right'}>
          <Button
            type={student.gradeId == -1 ? 'primary' : undefined}
            size="small"
            style={{ marginRight: '0.5rem' }}
            onClick={() => setGrade(-1)}
          >
            TK
          </Button>
          <Button
            type={student.gradeId == 0 ? 'primary' : undefined}
            size="small"
            onClick={() => setGrade(0)}
          >
            K
          </Button>
        </td>
      )}
    </tr>
  );
};

interface IIncomingKModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const IncomingKModal: FC<IIncomingKModalProps> = ({ isOpen, onClose }) => {
  // If you have a promotion day that indicates a certain enrollment milestone:
  const getPromotionDay = useGetPromotionDay();
  const promotionDay = dayjs(getPromotionDay.data).toDate();

  // Retrieve student data (this is just a custom hook example):
  const {
    data: students,
    isLoading: studentsIsLoading,
    refetch: studentsRefetch,
  } = useGetStudents();

  // const dispatch = useDispatch(); // If you're using Redux

  // Determine the current enrollment period based on today's date:
  const timeOfYear = () => {
    const today = new Date();
    const june = new Date(new Date().getFullYear(), 5); // June = month index 5
    const august = new Date(new Date().getFullYear(), 7); // August = month index 7

    if (today > june) {
      if (today > promotionDay) {
        if (today > august) return enrollmentPeriod.currentYear;
        return enrollmentPeriod.lateEnrollment;
      }
      return enrollmentPeriod.earlyEnrollment;
    }
    return enrollmentPeriod.currentYear;
  };

  // Build the title text (similar to the old logic):
  const kinderText = 'Kindergartners or Transitional Kindergartners';
  const title = () => {
    // If it's still early in the year (before Feb), we might bump the year
    const yearMod =
      timeOfYear() === enrollmentPeriod.currentYear && new Date().getMonth() < 2
        ? 1
        : 0;
    const year = new Date().getFullYear() + yearMod;

    if (timeOfYear() === enrollmentPeriod.currentYear) {
      return 'currently enrolled as ' + kinderText;
    }
    if (timeOfYear() === enrollmentPeriod.earlyEnrollment) {
      return 'enrolling next school year as ' + kinderText;
    }
    return (
      'incoming ' +
      kinderText +
      ' for the ' +
      year +
      ' - ' +
      (year + 1) +
      ' school year'
    );
  };

  const handleDone = () => onClose();

  return (
    <Modal
      title="Students Enrollment"
      okText="Next"
      open={isOpen}
      footer={null}
      closable={false}
    >
      <Pt3>
        <p style={{ color: 'rgb(112, 56, 149)' }}>
          <b>Are any of your students {title()}?</b>
        </p>
      </Pt3>

      <hr className={'mt-3 mb-3'} />

      <p>
        This will impact enrollment in summer camps. Students that will be
        Kindergartners in the Fall may only enroll in Kinder camp. Students that
        are in Kindergarten now will enroll in all other camps.{' '}
        <b>Staff will be checking all these students.</b>
      </p>

      <hr className={'mt-3 mb-4'} />
      <div>
        {!studentsIsLoading && students && (
          <table style={{ width: '100%', marginTop: '1rem' }}>
            <tbody>
              {students.filter(qualified).map((student: any, index: number) => (
                <StudentDiv
                  key={student.id || index}
                  student={student}
                  onSave={() => studentsRefetch()}
                />
              ))}
              <tr>
                <td colSpan={2} style={{ paddingTop: '1rem' }}>
                  <Button onClick={handleDone} style={{ width: '100%' }}>
                    None/Done
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <Pt3>
        <div className={'text-right'}>
          <Button type="primary" onClick={handleDone}>
            Next Question
          </Button>
        </div>
      </Pt3>
    </Modal>
  );
};

export default IncomingKModal;
