import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Tag, Typography, Tooltip, message } from 'antd';
import { StudentWithProps } from '../../../../hooks/useGetStudents';
import EnrollmentStatusMessage from '../../../../components/EnrollmentStatusMessage';
import {
  useGetStudentEnrollmentStatus,
  useCheckScheduleConflicts,
  WeekdaySelection,
} from '../../../../hooks/useGetStudentEnrollmentStatus';
import { useGet as useGetLocalSchedule } from '../../../../hooks/localSchedule/useGet';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { Text } = Typography;

/**
 * Converts a "HH:mm:ss" string into a numeric value for easy comparison.
 */
export const convertTimeToNumber = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours + minutes / 60 + (seconds || 0) / 3600;
};

/**
 * Checks if two date ranges overlap.
 */
export const dateRangeOverlaps = (
  aStart: string,
  aEnd: string,
  bStart: string,
  bEnd: string
): boolean => {
  const startA = Date.parse(aStart);
  const endA = Date.parse(aEnd);
  const startB = Date.parse(bStart);
  const endB = Date.parse(bEnd);

  return (
    (startA <= startB && startB <= endA) ||
    (startA <= endB && endB <= endA) ||
    (startB <= startA && endA <= endB)
  );
};

/**
 * Checks if two time ranges overlap (in the same day).
 */
export const timeRangeOverlaps = (
  aStart: string,
  aEnd: string,
  bStart: string,
  bEnd: string
): boolean => {
  const startA = convertTimeToNumber(aStart);
  const endA = convertTimeToNumber(aEnd);
  const startB = convertTimeToNumber(bStart);
  const endB = convertTimeToNumber(bEnd);

  return (
    (startA < startB && startB < endA) ||
    (startA < endB && endB < endA) ||
    (startB < startA && endA < endB)
  );
};

type WeekDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday';

/**
 * Example concurrency discount interface. Adjust or remove if needed.
 */
interface IConcurrentDiscount {
  concurrentId: number;
  oneDay: number;
  twoDay: number;
  threeDay: number;
  fourDay: number;
  fiveDay: number;
}

/**
 * Minimal schedule shape. Adjust if needed.
 */
interface ISchedule {
  id: number;
  name: string;
  dayAndTime?: string;
  programListingId?: number;
  // concurrency type: 1 => allowed, 2 => partial, 3 => disallowed if any other concurrency
  concurrent?: number;
  hidePricing?: boolean;
  fee?: number | string;
  oneDay?: number | string;
  twoDay?: number | string;
  threeDay?: number | string;
  fourDay?: number | string;
  fiveDay?: number | string;
  classSizeMax?: number;
  startTime?: string;
  endTime?: string;
  fullTime?: boolean;
  twoDayMinimum?: boolean;

  // Booleans to indicate which days are available
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;

  // Current enrolled count for each day
  mondayEnrollment?: number;
  tuesdayEnrollment?: number;
  wednesdayEnrollment?: number;
  thursdayEnrollment?: number;
  fridayEnrollment?: number;
}

/**
 * How we structure the event payload for parent components.
 */
export interface SelectionPayload {
  student: StudentWithProps;
  studentId: number;
  scheduleId: number;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  totalDays: number;
  fee: number;
}

/**
 * A shape for the parent's existing selections, if needed.
 */
export interface ICurrentSelection {
  scheduleId: number; // or string
  days: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
  };
}

/**
 * A shape for the student's enrolled schedule from DB, if needed.
 */
interface IEnrolledSchedule {
  scheduleId: number;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  // Possibly more fields
  startTime?: string;
  endTime?: string;
  programListing?: {
    startDate: string;
    endDate: string;
    registrationWindowId?: number;
  };
  concurrent?: number;
}

/**
 * Props for the WeekSelector with optional concurrency features.
 */
interface IWeekSelectorProps {
  program: any; // or your type
  schedule: ISchedule;
  student: StudentWithProps;
  gradeMinimum?: { id: number; shortGrade: string };
  gradeMaximum?: { id: number; shortGrade: string };
  onChangeSelection?: (payload: SelectionPayload) => void;

  /**
   * If you want concurrency discount logic:
   */
  concurrencyDiscounts?: IConcurrentDiscount[];

  /**
   * The parent's or global list of currently selected schedules
   * that are not yet submitted. This is for concurrency conflict checks or discount logic.
   */
  currentSelections?: ICurrentSelection[];

  /**
   * The student's actually enrolled schedules from the DB, for concurrency conflict checks.
   */
  enrolledSchedules?: IEnrolledSchedule[];

  /**
   * ELOP or organizational-account checks. If the schedule is funded, you might pass them here.
   */
  organizationalAccount?: { userId: number; displayName: string };
  schedulesBilledTo?: { scheduleId: number; userId: number }[];

  /**
   * If you want to mark the schedule as fully paid by the org, so fee = 0:
   */
  isElopEligible?: boolean;

  /**
   * A function or flag that says "stop validation bubble" in the old code.
   */
  onStopValidate?: () => void;
}

/**
 * Props for the DayItem component, including disabled state and loading indicator.
 */
interface DayItemProps {
  dayLabel: string;
  dayFull: boolean;
  conflict: boolean;
  selected: boolean;
  disabled?: boolean;
  loading?: boolean;
  onToggle: () => void;
}

/**
 * Component for rendering each day button/tag in the week selector
 */
const DayItem: FC<DayItemProps> = ({
  dayLabel,
  dayFull,
  conflict,
  selected,
  disabled = false,
  loading = false,
  onToggle,
}) => {
  if (dayFull) {
    return (
      <Tooltip title="Class full">
        <Tag style={{ marginLeft: '4px', marginRight: '4px' }} color="red">
          {dayLabel}
        </Tag>
      </Tooltip>
    );
  }
  if (conflict) {
    return (
      <Tooltip title="Schedule conflict">
        <Tag style={{ marginLeft: '4px', marginRight: '4px' }} color="orange">
          {dayLabel}
        </Tag>
      </Tooltip>
    );
  }
  return (
    <Button
      size="small"
      style={{ marginLeft: '4px', marginRight: '4px' }}
      type={selected ? 'primary' : 'default'}
      disabled={disabled}
      onClick={onToggle}
      loading={loading}
    >
      {dayLabel}
    </Button>
  );
};

/**
 * Checks if two schedules conflict by time range.
 */
const schedulesTimeConflict = (
  schedule1: {
    startTime?: string;
    endTime?: string;
  },
  schedule2: {
    startTime?: string;
    endTime?: string;
  }
): boolean => {
  const startTime1 = schedule1.startTime || '';
  const endTime1 = schedule1.endTime || '';
  const startTime2 = schedule2.startTime || '';
  const endTime2 = schedule2.endTime || '';

  if (!startTime1 || !endTime1 || !startTime2 || !endTime2) {
    return false;
  }

  return startTime1 < endTime2 && startTime2 < endTime1;
};

const getAllDatesBetweenRange = (
  weekDay: string,
  startDate: string,
  endDate: string
) => {
  const dates: string[] = [];
  const start = dayjs(startDate, 'YYYY-MM-DD');
  const end = dayjs(endDate, 'YYYY-MM-DD');

  // Normalize weekday name to capitalize the first letter
  const normalizedWeekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);

  // Map to convert weekday name to number
  const weekDayMap: Record<string, number> = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const targetWeekDay = weekDayMap[normalizedWeekDay];

  if (targetWeekDay === undefined) {
    throw new Error(`Invalid weekDay: ${weekDay}`);
  }

  let current = start;

  while (current.isBefore(end) || current.isSame(end, 'day')) {
    if (current.day() === targetWeekDay) {
      dates.push(current.format('YYYY-MM-DD'));
    }
    current = current.add(1, 'day');
  }

  return dates;
};

const WeekSelector: FC<IWeekSelectorProps> = ({
  program,
  schedule,
  student,
  gradeMinimum,
  gradeMaximum,
  onChangeSelection,
  concurrencyDiscounts,
  currentSelections = [],
  enrolledSchedules = [],
  organizationalAccount,
  schedulesBilledTo,
  isElopEligible: isElopEligibleProp,
  onStopValidate,
}) => {
  const localSchedules = useGetLocalSchedule();

  // State to track which days are selected
  const [days, setDays] = useState<Record<WeekDays, boolean>>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
  });

  // State to track previous day selections (for reverting in case of conflicts)
  const [previousDays, setPreviousDays] = useState<Record<WeekDays, boolean>>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
  });

  // State to track day-level conflicts
  const [conflicts, setConflicts] = useState<Record<WeekDays, boolean>>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
  });

  // NEW: State to disable days that would cause an error/conflict on initialization
  const [dayDisabled, setDayDisabled] = useState<Record<WeekDays, boolean>>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
  });

  // Whether we should apply concurrency discount
  const [useDiscount, setUseDiscount] = useState(false);

  // Whether the schedule is ELOP-eligible or fully funded, thus zero cost
  const [isElopEligible, setIsElopEligible] = useState(false);

  // State to control ongoing conflict check
  const [isCheckingConflicts, setIsCheckingConflicts] = useState(false);

  // Use the same hook as EnrollmentStatusMessage to check enrollment status
  const { data: enrollmentStatus } = useGetStudentEnrollmentStatus(
    student.id,
    schedule.id
  );

  // Hook for backend conflict checking
  const { mutateAsync: checkScheduleConflicts, isLoading: isConflictsLoading } =
    useCheckScheduleConflicts();

  // Check if there's any enrollment status that prevents selection
  const hasEnrollmentRestriction = useMemo(() => {
    if (!enrollmentStatus) return false;
    return (
      enrollmentStatus.isEnrolled ||
      enrollmentStatus.isPending ||
      enrollmentStatus.hasTimeConflict
    );
  }, [enrollmentStatus]);

  // 1) GRADE ELIGIBILITY CHECK
  const isStudentGradeEligible = useMemo(() => {
    if (!gradeMinimum?.id || !gradeMaximum?.id) return true;
    return (
      student.gradeId >= gradeMinimum.id && student.gradeId <= gradeMaximum.id
    );
  }, [student.gradeId, gradeMinimum, gradeMaximum]);

  // 2) ELOP ELIGIBILITY CHECK
  useEffect(() => {
    if (typeof isElopEligibleProp === 'boolean') {
      setIsElopEligible(isElopEligibleProp);
      return;
    }

    if (schedulesBilledTo && organizationalAccount) {
      const found = schedulesBilledTo.find(
        (b) =>
          b.scheduleId === schedule.id &&
          b.userId === organizationalAccount.userId
      );
      if (found) {
        setIsElopEligible(true);
      }
    }
  }, [
    isElopEligibleProp,
    schedulesBilledTo,
    organizationalAccount,
    schedule.id,
  ]);

  // 3) CONCURRENCY DISCOUNT CHECK
  useEffect(() => {
    if (!concurrencyDiscounts || !concurrencyDiscounts.length) {
      setUseDiscount(false);
      return;
    }

    let discountApplied = false;

    concurrencyDiscounts.forEach((disc) => {
      currentSelections.forEach((sel) => {
        if (sel.scheduleId === disc.concurrentId) {
          const dayCount = Object.values(sel.days).filter(Boolean).length;
          if (dayCount > 1) {
            discountApplied = true;
          }
        }
      });
      // Also check enrolled schedules
      enrolledSchedules.forEach((enr) => {
        if (enr.scheduleId === disc.concurrentId) {
          const dayCount =
            (enr.monday ? 1 : 0) +
            (enr.tuesday ? 1 : 0) +
            (enr.wednesday ? 1 : 0) +
            (enr.thursday ? 1 : 0) +
            (enr.friday ? 1 : 0);
          if (dayCount > 1) {
            discountApplied = true;
          }
        }
      });
    });

    setUseDiscount(discountApplied);
  }, [concurrencyDiscounts, currentSelections, enrolledSchedules]);

  // 4) FEE CALCULATION
  const calcFee = useCallback(
    (selectedDays: Record<WeekDays, boolean>): number => {
      if (schedule.hidePricing) return 0;
      if (isElopEligible) return 0;

      const staticFee = Number(schedule?.fee) || 0;
      const totalSelected = Object.values(selectedDays).filter(Boolean).length;

      if (totalSelected === 0) return 0;

      if (useDiscount && concurrencyDiscounts && concurrencyDiscounts.length) {
        // Assume concurrencyDiscounts[0] is for this schedule
        const disc = concurrencyDiscounts[0];
        switch (totalSelected) {
          case 1:
            return Number(disc.oneDay) || 0;
          case 2:
            return Number(disc.twoDay) || 0;
          case 3:
            return Number(disc.threeDay) || 0;
          case 4:
            return Number(disc.fourDay) || 0;
          case 5:
            return Number(disc.fiveDay) || 0;
          default:
            return 0;
        }
      } else {
        switch (totalSelected) {
          case 1:
            return Number(schedule.oneDay || 0) + staticFee;
          case 2:
            return Number(schedule.twoDay || 0) + staticFee;
          case 3:
            return Number(schedule.threeDay || 0) + staticFee;
          case 4:
            return Number(schedule.fourDay || 0) + staticFee;
          case 5:
            return Number(schedule.fiveDay || 0) + staticFee;
          default:
            return 0;
        }
      }
    },
    [schedule, isElopEligible, useDiscount, concurrencyDiscounts]
  );

  // 5) LOCAL CONFLICT CHECK
  const checkLocalConflicts = useCallback(
    (selectedDays: Record<WeekDays, boolean>) => {
      const newConflicts = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      };

      // Early exit if this schedule has concurrent=1 (full overlap allowed)
      if (schedule.concurrent === 1) {
        return newConflicts; // No conflicts, all overlaps allowed
      }

      // The concurrent value for the schedule we're trying to add
      const newScheduleConcurrent = schedule.concurrent || 3; // Default to most restrictive

      // Compare with enrolled schedules
      for (const enr of enrolledSchedules) {
        // Skip if not in the same registration window
        if (
          program.registrationWindowId !==
          enr.programListing?.registrationWindowId
        ) {
          continue;
        }

        // If enrolled schedule has concurrent=1, skip conflict check for this schedule
        if (enr.concurrent === 1) continue;

        // Get the most restrictive concurrent value between the two schedules
        const enrolledConcurrent = enr.concurrent || 3; // Default to most restrictive
        const effectiveConcurrent = Math.max(
          newScheduleConcurrent,
          enrolledConcurrent
        );

        // If effectiveConcurrent is 1, allow full overlap for this pair
        if (effectiveConcurrent === 1) continue;

        // Check date overlap first - if no date overlap, no conflict
        const scheduleStartDate = program.startDate;
        const scheduleEndDate = program.endDate;
        const enrolledStartDate = enr.programListing?.startDate || '';
        const enrolledEndDate = enr.programListing?.endDate || '';

        const dateOverlap = dateRangeOverlaps(
          scheduleStartDate,
          scheduleEndDate,
          enrolledStartDate,
          enrolledEndDate
        );

        if (!dateOverlap) continue;

        // Check day overlap - if no day overlap, no conflict
        let hasDayOverlap = false;
        if (selectedDays.monday && enr.monday) hasDayOverlap = true;
        if (selectedDays.tuesday && enr.tuesday) hasDayOverlap = true;
        if (selectedDays.wednesday && enr.wednesday) hasDayOverlap = true;
        if (selectedDays.thursday && enr.thursday) hasDayOverlap = true;
        if (selectedDays.friday && enr.friday) hasDayOverlap = true;

        if (!hasDayOverlap) continue;

        // If effectiveConcurrent is 2, only check time overlaps (day overlap is allowed)
        if (effectiveConcurrent === 2) {
          const timeOverlap = timeRangeOverlaps(
            schedule.startTime || '',
            schedule.endTime || '',
            enr.startTime || '',
            enr.endTime || ''
          );

          if (timeOverlap) {
            // Mark days that have overlap as conflicting
            if (selectedDays.monday && enr.monday) newConflicts.monday = true;
            if (selectedDays.tuesday && enr.tuesday)
              newConflicts.tuesday = true;
            if (selectedDays.wednesday && enr.wednesday)
              newConflicts.wednesday = true;
            if (selectedDays.thursday && enr.thursday)
              newConflicts.thursday = true;
            if (selectedDays.friday && enr.friday) newConflicts.friday = true;
          }
        } else if (effectiveConcurrent === 3) {
          // For concurrent=3, check both day and time overlap (most restrictive)
          // Mark any overlapping day as a conflict
          if (selectedDays.monday && enr.monday) newConflicts.monday = true;
          if (selectedDays.tuesday && enr.tuesday) newConflicts.tuesday = true;
          if (selectedDays.wednesday && enr.wednesday)
            newConflicts.wednesday = true;
          if (selectedDays.thursday && enr.thursday)
            newConflicts.thursday = true;
          if (selectedDays.friday && enr.friday) newConflicts.friday = true;
        }
      }

      return newConflicts;
    },
    [schedule, enrolledSchedules, program]
  );

  // 6) SEND CHANGES UPWARD WHENEVER `days` CHANGES
  useEffect(() => {
    const newConflicts = checkLocalConflicts(days);
    setConflicts(newConflicts);

    const totalDays = Object.values(days).filter(Boolean).length;
    const newFee = calcFee(days);

    onChangeSelection?.({
      student,
      studentId: student.id,
      scheduleId: schedule.id,
      ...days,
      totalDays,
      fee: newFee,
    });

    if (schedule.twoDayMinimum && totalDays === 1 && onStopValidate) {
      onStopValidate();
    }
  }, [
    days,
    schedule,
    student.id,
    onChangeSelection,
    calcFee,
    checkLocalConflicts,
    onStopValidate,
  ]);

  // 7) TOGGLE DAY (MODIFIED TO ACCEPT checkOnly)
  const toggleDay = useCallback(
    async (day: WeekDays, checkOnly = false): Promise<boolean> => {
      const isAddingDay = !days[day];

      // 7a) Local time conflict & date conflict checks
      if (isAddingDay) {
        let enrolledDates: string[] = [];
        let addingDates: string[] = [];

        addingDates = addingDates.concat(
          getAllDatesBetweenRange(day, program.startDate, program.endDate)
        );

        for (const selection of localSchedules?.selections || []) {
          if (selection.studentId !== student.id) {
            continue;
          }

          // Skip if not in the same registration window
          if (
            program.registrationWindowId !==
            selection.program?.registrationWindowId
          ) {
            continue;
          }

          if (selection.scheduleId === schedule.id) {
            if (!checkOnly) {
              message.error({
                content: (
                  <div>
                    <p>You are already enrolled in this program.</p>
                  </div>
                ),
                duration: 6,
                style: { whiteSpace: 'pre-wrap' },
              });
            }
            return false;
          }

          // Check concurrent values - respect the most restrictive between the two schedules
          const newScheduleConcurrent = schedule.concurrent || 3; // Default to most restrictive
          const selectionConcurrent = selection.schedule?.concurrent || 3;
          const effectiveConcurrent = Math.max(
            newScheduleConcurrent,
            selectionConcurrent
          );

          // If effectiveConcurrent is 1, allow full overlap for this pair
          if (effectiveConcurrent === 1) continue;

          // Check if there are time conflicts based on effective concurrent value
          if (effectiveConcurrent >= 2) {
            if (selection[day]) {
              // For concurrent=2, check time overlaps
              if (effectiveConcurrent === 2) {
                const timeOverlap = schedulesTimeConflict(
                  schedule,
                  selection.schedule
                );
                if (timeOverlap) {
                  enrolledDates = enrolledDates.concat(
                    getAllDatesBetweenRange(
                      day,
                      selection.program.startDate,
                      selection.program.endDate
                    )
                  );
                }
              } else if (effectiveConcurrent === 3) {
                // For concurrent=3, any day overlap is a conflict
                enrolledDates = enrolledDates.concat(
                  getAllDatesBetweenRange(
                    day,
                    selection.program.startDate,
                    selection.program.endDate
                  )
                );
              }
            }
          }
        }

        for (const date of addingDates) {
          if (enrolledDates.includes(date)) {
            if (!checkOnly) {
              message.error({
                content: (
                  <div>
                    <p>You are already enrolled in this day.</p>
                  </div>
                ),
                duration: 6,
                style: { whiteSpace: 'pre-wrap' },
              });
            }
            return false;
          }
        }
      }

      // If we're only checking conflicts, skip changing state or showing messages
      if (checkOnly) {
        try {
          const newDays = { ...days, [day]: !days[day] };
          const selectedDays: WeekdaySelection = {};
          Object.entries(newDays).forEach(([key, value]) => {
            if (value) selectedDays[key as WeekDays] = true;
          });

          const result = await checkScheduleConflicts({
            studentId: student.id,
            scheduleId: schedule.id,
            selectedDays,
          });

          if (result.hasTimeConflict) {
            return false;
          }

          return true;
        } catch {
          return false;
        }
      }

      // 7b) Normal path if not checkOnly
      setPreviousDays({ ...days });
      const newDays = { ...days, [day]: isAddingDay };
      setDays(newDays);

      try {
        setIsCheckingConflicts(true);
        if (isAddingDay) {
          const selectedDays: WeekdaySelection = {};
          Object.entries(newDays).forEach(([key, value]) => {
            if (value) {
              selectedDays[key as WeekDays] = true;
            }
          });

          const result = await checkScheduleConflicts({
            studentId: student.id,
            scheduleId: schedule.id,
            selectedDays,
          });

          if (result.hasTimeConflict) {
            setDays(previousDays);

            let errorMessage =
              'Error checking schedule conflicts. Please try again.';

            if (result && typeof result === 'object') {
              const apiError = result as any;
              errorMessage =
                apiError?.response?.data?.message ||
                'Error checking schedule conflicts. Please try again.';
            }

            message.error({
              content: (
                <div>
                  {errorMessage
                    .split('\n')
                    .map((line: string, index: number) => (
                      <div key={index}>{line}</div>
                    ))}
                </div>
              ),
              duration: 6,
              style: { whiteSpace: 'pre-wrap' },
            });
            return false;
          }
        }
        return true;
      } catch (error) {
        console.error('Error checking conflicts for selection:', error);
        setDays(previousDays);

        let errorMessage =
          'Error checking schedule conflicts. Please try again.';

        if (error && typeof error === 'object') {
          const apiError = error as any;
          errorMessage =
            apiError?.response?.data?.message ||
            'Error checking schedule conflicts. Please try again.';
        }

        message.error({
          content: (
            <div>
              {errorMessage.split('\n').map((line: string, index: number) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          ),
          duration: 6,
          style: { whiteSpace: 'pre-wrap' },
        });
        return false;
      } finally {
        setIsCheckingConflicts(false);
      }
    },
    [
      days,
      student.id,
      schedule,
      localSchedules?.selections,
      previousDays,
      program,
      checkScheduleConflicts,
    ]
  );

  // 8) TOGGLE ALL DAYS
  const toggleAllDays = useCallback(async () => {
    setPreviousDays({ ...days });
    const newDays = {
      monday: schedule.monday ? !days.monday : days.monday,
      tuesday: schedule.tuesday ? !days.tuesday : days.tuesday,
      wednesday: schedule.wednesday ? !days.wednesday : days.wednesday,
      thursday: schedule.thursday ? !days.thursday : days.thursday,
      friday: schedule.friday ? !days.friday : days.friday,
    };

    setDays(newDays);

    try {
      setIsCheckingConflicts(true);

      const hasNewDay = Object.entries(newDays).some(
        ([key, value]) => value && !days[key as WeekDays]
      );

      if (hasNewDay) {
        const selectedDays: WeekdaySelection = {};
        Object.entries(newDays).forEach(([key, value]) => {
          if (value) {
            selectedDays[key as WeekDays] = true;
          }
        });

        const result = await checkScheduleConflicts({
          studentId: student.id,
          scheduleId: schedule.id,
          selectedDays,
        });

        if (result.hasTimeConflict) {
          setDays(previousDays);

          let errorMessage =
            'Error checking schedule conflicts. Please try again.';

          if (result && typeof result === 'object') {
            const apiError = result as any;

            if (apiError.message && typeof apiError.message === 'string') {
              errorMessage = apiError.message;
            } else if (apiError.error?.message) {
              errorMessage = apiError.error.message;
            } else if (apiError.code && apiError.error?.stack) {
              const stackMatch =
                apiError.error.stack.match(/Error: (.*?)(\n|$)/);
              if (stackMatch && stackMatch[1]) {
                errorMessage = stackMatch[1];
              }
            }
          }

          message.error({
            content: (
              <div>
                {errorMessage.split('\n').map((line: string, index: number) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            ),
            duration: 6,
            style: { whiteSpace: 'pre-wrap' },
          });
        }
      }
    } catch (error) {
      console.error('Error checking conflicts for complete selection:', error);
      setDays(previousDays);

      let errorMessage = 'Error checking schedule conflicts. Please try again.';

      if (error && typeof error === 'object') {
        const apiError = error as any;

        if (apiError.message && typeof apiError.message === 'string') {
          errorMessage = apiError.message;
        } else if (apiError.error?.message) {
          errorMessage = apiError.error.message;
        } else if (apiError.code && apiError.error?.stack) {
          const stackMatch = apiError.error.stack.match(/Error: (.*?)(\n|$)/);
          if (stackMatch && stackMatch[1]) {
            errorMessage = stackMatch[1];
          }
        }
      }

      message.error({
        content: (
          <div>
            {errorMessage.split('\n').map((line: string, index: number) => (
              <div key={index}>{line}</div>
            ))}
          </div>
        ),
        duration: 6,
        style: { whiteSpace: 'pre-wrap' },
      });
    } finally {
      setIsCheckingConflicts(false);
    }
  }, [days, schedule, student.id, checkScheduleConflicts, previousDays]);

  // 9) ON COMPONENT MOUNT, CHECK WHICH DAYS WOULD FAIL AND DISABLE THEM
  useEffect(() => {
    const checkInitialDayConflicts = async () => {
      const updatedDisabled: Record<WeekDays, boolean> = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      };

      // Only check days that are available in this schedule
      const allDays: WeekDays[] = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
      ];

      for (const d of allDays) {
        if (!schedule[d]) continue;
        // Test toggling this day. If toggleDay returns false, disable it.
        const canSelect = await toggleDay(d, true);
        if (!canSelect) {
          updatedDisabled[d] = true;
        }
      }

      // if a day is disabled, we need to disable all days
      const hasDisabledDay = Object.values(updatedDisabled).some(Boolean);
      if (hasDisabledDay) {
        for (const d of allDays) {
          updatedDisabled[d] = true;
        }
      }

      console.log('updatedDisabled', hasDisabledDay, updatedDisabled);

      setDayDisabled(updatedDisabled);
    };

    checkInitialDayConflicts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // EARLY RETURNS
  if (!isStudentGradeEligible) {
    return null;
  }

  if (hasEnrollmentRestriction) {
    return (
      <div className="week-selector">
        <EnrollmentStatusMessage
          studentId={student.id}
          scheduleId={schedule.id}
        />
      </div>
    );
  }

  // RENDER
  const totalSelected = Object.values(days).filter(Boolean).length;
  const needsTwoDaysError = !!schedule.twoDayMinimum && totalSelected === 1;
  const isGlobalLoading = isCheckingConflicts || isConflictsLoading;

  return (
    <div className="week-selector">
      <div style={{ border: '1px solid #ccc', marginTop: 8, padding: 8 }}>
        <div className={'text-center'}>
          <div style={{ marginBottom: '4px' }}>
            <small>
              {schedule.name}: {schedule.dayAndTime}
              {schedule.twoDayMinimum ? ' - Two Day Minimum' : ''}
            </small>
          </div>

          {isElopEligible && organizationalAccount && (
            <div style={{ marginTop: '4px', marginBottom: '4px' }}>
              <small>
                This schedule is {organizationalAccount.displayName} funded
              </small>
            </div>
          )}
        </div>

        {dayDisabled.monday ? (
          <div className="text-center">
            <small style={{ color: 'red' }}>
              This student already has a class on this week.
            </small>
          </div>
        ) : (
          <>
            <div className={'text-center'}>
              {(
                [
                  'monday',
                  'tuesday',
                  'wednesday',
                  'thursday',
                  'friday',
                ] as WeekDays[]
              ).map((day) => {
                if (!schedule[day]) return null;
                const dayLabel = day.charAt(0).toUpperCase() + day.slice(1, 3);
                const dayFull =
                  (schedule[`${day}Enrollment`] || 0) >=
                  (schedule.classSizeMax || 999999);
                const loading = isGlobalLoading && !dayDisabled[day];
                const disabled = isGlobalLoading || dayDisabled[day];

                return (
                  <DayItem
                    key={day}
                    dayLabel={dayLabel}
                    dayFull={dayFull}
                    conflict={conflicts[day]}
                    selected={days[day]}
                    disabled={disabled}
                    loading={loading}
                    onToggle={() => {
                      if (schedule.fullTime) {
                        toggleAllDays();
                      } else {
                        toggleDay(day);
                      }
                    }}
                  />
                );
              })}
            </div>
            <p className={'pt-1 text-center'}>
              <small>
                Selected Monthly Tuition:{' '}
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(calcFee(days))}
              </small>
            </p>

            {needsTwoDaysError && (
              <Text
                type="danger"
                style={{ marginTop: 8, display: 'inline-block' }}
              >
                This schedule requires at least 2 days.
              </Text>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WeekSelector;
