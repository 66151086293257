import React, { FC } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useLogout } from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import { useFetchUser } from '../../hooks/useFetchUser';

import starLogo from '../../assets/images/star-logo-ver-8.png';

const Header: FC = () => {
  const user = useFetchUser();
  const logout = useLogout();
  const navigate = useNavigate();

  const handleNavigate = (event: React.MouseEvent, path: string) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <>
      <Container fluid className={'p-2'}>
        <img alt={'Logo'} src={starLogo} style={{ width: '70px' }} />
      </Container>

      <Navbar variant="dark" expand="lg" style={{ backgroundColor: '#1f9bd6' }}>
        <Container fluid>
          <Navbar.Brand>Welcome to STAR Program Registration</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {window.innerWidth < 767 &&
                (constants.menuItems || []).map((item) => (
                  <Nav.Link
                    key={item.label}
                    onClick={(event) => handleNavigate(event, item.path)}
                    href="#"
                  >
                    {item.icon} {item.label}
                  </Nav.Link>
                ))}
            </Nav>

            {user.data && user.data.id ? (
              <Nav>
                <button
                  id={'logout-button'}
                  onClick={logout}
                  className="btn btn-primary ml-2 btn-sac"
                >
                  Logout
                </button>
              </Nav>
            ) : (
              <Nav>
                <button
                  id={'login-button'}
                  onClick={logout}
                  className="btn btn-primary ml-2 btn-sac"
                >
                  Login
                </button>
                <a
                  id={'login-button'}
                  href={'https://accounts.starsacramento.org/'}
                  className="btn btn-primary ml-2 btn-sac"
                >
                  Create Account
                </a>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
