// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useState } from 'react';
import { Button, Card } from 'antd';
import Program from './Program';
import { CurrentlyEnrollingProgram } from '../SchedulingPage';

const ProgramList: FC<{
  title: string;
  backgroundColor: string;
  programs: CurrentlyEnrollingProgram[];
}> = ({ title, backgroundColor, programs }) => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const togleContent = (ev: any) => {
    ev.preventDefault();
    setShowContent(!showContent);
  };

  return (
    <Card
      headStyle={{ backgroundColor }}
      title={title}
      bodyStyle={{ padding: 0 }}
      extra={
        <Button
          id={'program-list-hide-show-button'}
          data-testid="toggle-button"
          type="dashed"
          href={'#'}
          onClick={togleContent}
        >
          {showContent ? 'Hide' : 'Show'}
        </Button>
      }
    >
      {showContent &&
        programs.map((program) => (
          <Program
            key={program.id}
            program={program}
            backgroundColor={backgroundColor}
          />
        ))}
    </Card>
  );
};

export default ProgramList;
