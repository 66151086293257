import React, { FC, ReactNode } from 'react';
import './Content.css';

interface ContentProps {
  children: ReactNode;
}

const Content: FC<ContentProps> = ({ children }) => {
  return <div className="content">{children}</div>;
};

export default Content;
