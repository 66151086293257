import { Result } from 'antd';
import { FC, ReactNode } from 'react';
import { ResultStatusType } from 'antd/es/result';

const OnError: FC<{
  title?: string;
  subTitle?: string;
  extra?: ReactNode;
  status?: ResultStatusType;
}> = ({
  title = 'Oops! Something went wrong',
  subTitle = 'Please try reloading the page by clicking the button below',
  extra,
  status = '500',
}) => (
  <Result status={status} title={title} subTitle={subTitle} extra={extra} />
);

export default OnError;
