import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LoginDiv from './components/LoginDiv';
import RegisterDiv from './components/RegisterDiv';

import './LoginPage.css';
import starLogo from '../../assets/images/star-logo-ver-8.png';
import bgImage from '../../assets/images/bg.png';
import Footer from '../../layout/Footer/Footer';

const LoginPage: FC = () => {
  return (
    <>
      <div id={'loginDiv'} style={{ backgroundImage: `url(${bgImage})` }}>
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div className={'pb-4'}>
                <img
                  src={starLogo}
                  alt="Star Logo"
                  className="mb-3"
                  id={'star-logo'}
                />
              </div>

              <LoginDiv />
              <RegisterDiv />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default LoginPage;
