import { useGet } from './useApi';

/**
 * Hook to fetch registration payment status
 * Returns a record of registrationWindowId -> payment status (true if paid)
 */
export const useGetRegistrationPaymentStatus = () => {
  return useGet<Record<number, boolean>>(
    '/v1/auth/reg/register-schedule/registration-payment-status',
    {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    }
  );
};
