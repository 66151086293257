// useGetRegistrationWindowsBilledTo.ts
import { useGet } from './useApi';
// import { RegistrationWindowBilledTo } from '../shared-typings/models/registration-window-billed-to.model';

export const useGetRegistrationWindowsBilledTo = () => {
  return useGet<any[]>('/v1/public/reg/registration-windows-billed-to', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
