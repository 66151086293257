import { useMemo } from 'react';
import { usePost } from './useApi';
import { useGetStudentEnrollmentStatus } from './useGetStudentEnrollmentStatus';

/**
 * Interface para a payload de validação de agendamentos
 */
interface ScheduleValidationPayload {
  studentId: number;
  scheduleId: number;
}

/**
 * Hook personalizado para validar agendamentos e verificar se um estudante
 * já está matriculado ou tem o horário selecionado
 *
 * @param studentId - ID do estudante
 * @param scheduleId - ID do agendamento
 * @returns Objeto com funções e estados para validação completa de agendamentos
 */
export const useScheduleValidator = (
  studentId?: number | null,
  scheduleId?: number | null
) => {
  // Hook para verificar se o estudante já está matriculado
  const enrollmentStatus = useGetStudentEnrollmentStatus(studentId, scheduleId);

  // Hook para validar o agendamento no servidor
  const validateMutation = usePost<boolean, ScheduleValidationPayload[]>(
    '/v1/auth/reg/register-schedule/validate-schedules'
  );

  // Determina se o estudante pode ser matriculado neste agendamento
  const canEnroll = useMemo(() => {
    // Se ainda está carregando, permitir por enquanto
    if (enrollmentStatus.isLoading) return true;

    // Se o servidor retornou dados, verificar se o estudante já está matriculado,
    // tem horário pendente ou tem conflito de horário com outro programa
    if (enrollmentStatus.data) {
      return (
        !enrollmentStatus.data.isEnrolled &&
        !enrollmentStatus.data.isPending &&
        !enrollmentStatus.data.hasTimeConflict
      );
    }

    // Se não há dados, permitir por padrão
    return true;
  }, [enrollmentStatus.isLoading, enrollmentStatus.data]);

  /**
   * Função para validar múltiplos agendamentos
   * @param schedules - Array de agendamentos a serem validados
   * @returns Promise com o resultado da validação
   */
  const validateSchedules = async (schedules: ScheduleValidationPayload[]) => {
    // Se o estudante não pode ser matriculado, rejeitar diretamente
    if (studentId && scheduleId && !canEnroll) {
      return Promise.reject(
        new Error(
          enrollmentStatus.data?.message ||
            'Student cannot be enrolled in this schedule'
        )
      );
    }

    // Caso contrário, enviar para o servidor validar
    return validateMutation.mutateAsync(schedules);
  };

  return {
    // Estados
    isLoading: enrollmentStatus.isLoading || validateMutation.isLoading,
    isValidating: validateMutation.isLoading,
    isError: enrollmentStatus.isError || validateMutation.isError,
    error: enrollmentStatus.error || validateMutation.error,
    enrollmentStatus: enrollmentStatus.data,

    // Propriedades derivadas
    canEnroll,

    // Métodos
    validateSchedules,

    // Estado bruto das mutations (caso seja necessário mais controle)
    validateMutation,
  };
};
