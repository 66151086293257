import { Button } from 'antd';
import React from 'react';

interface NextStepButtonProps {
  text?: string;
  disabled: boolean;
  onClick: () => void;
}

const NextStepButton: React.FC<NextStepButtonProps> = ({
  text = 'Next Step',
  disabled,
  onClick,
}) => {
  return (
    <Button
      data-testid={'next-step-button'}
      disabled={disabled}
      type={'primary'}
      block={true}
      size={'large'}
      onClick={onClick}
    >
      <table className={'w-100'}>
        <tr>
          <td style={{ width: '30px' }}></td>
          <td>{text}</td>
          <td className={'text-right'} style={{ width: '30px' }}>
            <i className={'fa fa-chevron-right'}></i>
          </td>
        </tr>
      </table>
    </Button>
  );
};

export default NextStepButton;
