import React from 'react';
import { DatePicker, Input, Select, Space, Row, Col, Typography } from 'antd';
import { Pt2 } from '../../../components/P';
import { Dayjs } from 'dayjs';
import { EnrollingSchool } from '../../../models/enrolling-school';

const { Text } = Typography;

// Define types for the component props
type ProgramType = {
  id: number;
  name: string;
  longName: string;
};

type School = EnrollingSchool;

type FilterTableProps = {
  selectedStartDate: Dayjs | null;
  setSelectedStartDate: (value: Dayjs | null) => void;
  selectedEndDate: Dayjs | null;
  setSelectedEndDate: (value: Dayjs | null) => void;
  selectedProgramTypes: number[];
  setSelectedProgramTypes: (value: number[]) => void;
  programTypes: ProgramType[];
  schools: School[];
  selectedSchools: number[];
  setSelectedSchools: (value: number[]) => void;
  search: string;
  setSearch: (value: string) => void;
};

const FilterTable: React.FC<FilterTableProps> = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  selectedProgramTypes,
  setSelectedProgramTypes,
  programTypes,
  schools,
  selectedSchools,
  setSelectedSchools,
  search,
  setSearch,
}) => {
  return (
    <div>
      {/* Date Range + Search Row */}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8} md={6}>
          <Space direction="vertical" className="w-100">
            <Text>From</Text>
            <DatePicker
              allowClear={true}
              value={selectedStartDate}
              onChange={setSelectedStartDate}
              className="w-100"
              placeholder="Select start date"
            />
          </Space>
        </Col>
        <Col xs={24} sm={8} md={6}>
          <Space direction="vertical" className="w-100">
            <Text>To</Text>
            <DatePicker
              allowClear={true}
              value={selectedEndDate}
              onChange={setSelectedEndDate}
              className="w-100"
              placeholder="Select end date"
            />
          </Space>
        </Col>
        <Col xs={24} sm={8} md={12}>
          <Space direction="vertical" className="w-100">
            <Text>Search</Text>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search by name..."
            />
          </Space>
        </Col>
      </Row>

      {/* Program & Location Filters */}
      <Pt2>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Space direction="vertical" className="w-100">
              <Text>Programs</Text>
              <Select
                aria-label="Program Select"
                placeholder="All Programs"
                value={selectedProgramTypes}
                onChange={setSelectedProgramTypes}
                mode="multiple"
                allowClear
                className="w-100"
                options={programTypes.map((program) => ({
                  value: program.id,
                  label: program.name,
                  desc: program.longName,
                }))}
                optionLabelProp="label"
              />
            </Space>
          </Col>
          <Col xs={24} md={12}>
            <Space direction="vertical" className="w-100">
              <Text>Location</Text>
              <Select
                aria-label="School Select"
                placeholder="All Locations"
                value={selectedSchools}
                onChange={setSelectedSchools}
                mode="multiple"
                allowClear
                className="w-100"
                options={schools.map((school) => ({
                  value: school.id,
                  label: school.shortName,
                  desc: school.name,
                }))}
                optionLabelProp="label"
              />
            </Space>
          </Col>
        </Row>
      </Pt2>
    </div>
  );
};

export default FilterTable;
