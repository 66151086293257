import { Button, Card, Typography } from 'antd';
import React, { FC } from 'react';
import { Pb1, Pb4 } from '../../../components/P';
import { Student } from '../../../shared-typings/models/student.model';
import dayjs from 'dayjs';
import { EnrollmentBlocked } from '../../../shared-typings/models/enrollment-blocked.model';

const { Title } = Typography;

type StudentWithEnrollmentBlocked = Student & {
  enrollmentBlocked?: EnrollmentBlocked;
};

const StudentDiv: FC<{
  student: StudentWithEnrollmentBlocked;
  qualified: boolean;
  beforePromotionDay: boolean;
  onEdit: (student: Student) => any;
}> = ({ student, qualified, onEdit }) => {
  const handleEditStudent = () => {
    onEdit(student as Student);
  };

  return (
    <Card style={{ marginBottom: 24, backgroundColor: '#fafafa' }}>
      {!qualified && (
        <>
          <table className={'w-100'}>
            <tbody>
              <tr>
                <td>
                  <Title level={4} style={{ marginBottom: 8 }}>
                    <i
                      style={{ color: 'orange' }}
                      className={'fa fa-triangle-exclamation'}
                    ></i>{' '}
                    Student Data Incomplete
                  </Title>
                </td>
                <td className={'text-right'}>
                  <a
                    href={'https://my.starsacramento.org'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button type="primary" size="small">
                      Go to Family Account
                    </Button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <hr className={'mt-3 mb-3'} />
        </>
      )}

      {student.enrollmentBlocked?.id && (
        <>
          <table className={'w-100'}>
            <tbody>
              <tr>
                <td>
                  <Title level={4} style={{ marginBottom: 8 }}>
                    <i
                      style={{ color: 'orange' }}
                      className={'fa fa-triangle-exclamation'}
                    ></i>{' '}
                    Ineligible for Enrollment - Expelled
                  </Title>
                </td>
              </tr>
            </tbody>
          </table>

          <hr className={'mt-3 mb-3'} />
        </>
      )}

      <Pb1>
        <table className={'w-100'}>
          <tbody>
            <tr>
              <td>
                <Title level={4} style={{ marginBottom: 8 }}>
                  {student.firstName} {student.lastName}
                </Title>
              </td>
              {qualified && (
                <td className={'text-right'}>
                  <Button
                    className={'btn-sac'}
                    onClick={handleEditStudent}
                    type="primary"
                    size="small"
                  >
                    Edit Student
                  </Button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </Pb1>

      {qualified ? (
        <hr className={'mt-3 mb-4'} />
      ) : (
        <Pb4>
          {
            "You will need to complete this student's information in your Family Account before you will be able to enroll them in programs."
          }
        </Pb4>
      )}

      <table className={'w-100 text-center'}>
        <thead>
          <tr>
            <th style={{ width: '33%' }}>Birthdate</th>
            <th style={{ width: '33%' }}>Grade</th>
            <th style={{ width: '33%' }}>School</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dayjs(student.birthdate).format('MM/DD/YYYY') || '-'}</td>
            <td>{student.Grade?.shortGrade || '-'}</td>
            <td>{student.School?.shortName || '-'}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default StudentDiv;
