// useGetSchools.ts
import { useGet } from './useApi';
import { School } from '../shared-typings/models';

export const useGetSchools = () => {
  return useGet<School[]>('/v1/public/reg/schools', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
