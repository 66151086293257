// useGetOrganizationalAccounts.ts
import { useGet } from './useApi';
// import { OrganizationalAccount } from '../shared-typings/models/organizational-account.model';

export const useGetOrganizationalAccounts = () => {
  return useGet<any[]>('/v1/public/reg/organizational-accounts', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
