// useGetProgramListings.ts
import { useGet } from './useApi';
import { ProgramListing } from '../shared-typings/models/program-listing.model';

export const useGetProgramListings = () => {
  return useGet<Record<string, ProgramListing[]>>(
    '/v1/public/reg/program-listings',
    {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    }
  );
};
