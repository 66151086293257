import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Alert } from 'react-bootstrap';
import { Pb3, Pb4 } from '../../../components/P';
import { Button, Result } from 'antd';
import SelectedSchedulesModal from '../modals/SelectedSchedulesModal';
import { useGet } from '../../../hooks/localSchedule/useGet';

dayjs.extend(duration);

const SavedSchedulling = () => {
  const schedule = useGet();
  const [modalVisible, setModalVisible] = useState(false);
  const [remainingMs, setRemainingMs] = useState(0);

  // When the schedule changes, set the initial remaining time.
  useEffect(() => {
    if (schedule) {
      const diff = dayjs(schedule.validTo).diff(dayjs());
      setRemainingMs(diff > 0 ? diff : 0);
    }
  }, [schedule]);

  // Update the remaining time every second.
  useEffect(() => {
    if (!schedule) return;
    const interval = setInterval(() => {
      const diff = dayjs(schedule.validTo).diff(dayjs());
      if (diff <= 0) {
        // When expired, just set remaining time to zero.
        setRemainingMs(0);
        clearInterval(interval);
        // No need to manually clear the schedule here since useGetSchedule handles it.
      } else {
        setRemainingMs(diff);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [schedule]);

  // If there's no schedule or there are no selections, don't render anything.
  if (!schedule || !schedule.selections?.length) {
    return null;
  }

  // Format the remaining time.
  const remainingDuration = dayjs.duration(remainingMs);
  const minutes = remainingDuration.minutes();
  const seconds = remainingDuration.seconds();

  const handleViewSchedules = () => {
    setModalVisible(true);
  };

  return (
    <>
      <Pb3>
        <SelectedSchedulesModal
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
        />

        <Alert style={{ padding: 0 }} variant="success">
          <Result
            status="success"
            title={`You have ${minutes.toString().padStart(2, '0')}:${seconds
              .toString()
              .padStart(2, '0')} left`}
            subTitle={
              <>
                Make charges to, or finalize this schedule.
                <br />
                Your reserved schedule WILL BE LOST once this session expires.
              </>
            }
            extra={[
              <Button onClick={handleViewSchedules} key="view">
                View Your Schedule
              </Button>,
            ]}
          />
        </Alert>
      </Pb3>

      <Pb4>
        <hr />
      </Pb4>
    </>
  );
};

export default SavedSchedulling;
