const color = {
  blue: '#289BD6',
  lightBlue: '#C9E6F5',
  purple: '#703895',
  lightPurple: '#DBCDE4',
  green: '#6CC069',
  lightGreen: '#DAEFD9',
  orange: '#F26522',
  lightOrange: '#FCD8C8',
  black: '#1A1A1A',
  white: '#FAFAFA',
  offWhite: '#F1F1F1',
  lightGray: '#E6E6E6',
  darkGray: '#505050',
};

export default {
  menuItems: [],
  galaxyColor: color.blue,
  galaxyColorLight: color.lightBlue,
  campsColor: color.purple,
  campsColorLight: color.lightPurple,
  preschoolColor: color.green,
  preschoolColorLight: color.lightGreen,
  novaColor: color.orange,
  novaColorLight: color.lightOrange,
};
