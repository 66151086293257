import React, { FC, useEffect } from 'react';
import {
  Modal,
  Form,
  Select,
  Input,
  DatePicker,
  notification,
  Skeleton,
} from 'antd';
import dayjs from 'dayjs';
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Student } from '../../../shared-typings/models';
import { Pt3 } from '../../../components/P';
import { usePut } from '../../../hooks/useApi';
import { useGetSchools } from '../../../hooks/useGetSchools';
import { useGetGrades } from '../../../hooks/useGetGrades';
import { useQueryClient } from 'react-query';

interface IEditStudentModalProps {
  student: Student;
  isOpen: boolean;
  onClose: () => void;
}

const EditStudentModal: FC<IEditStudentModalProps> = ({
  student,
  isOpen,
  onClose,
}) => {
  const schools = useGetSchools();
  const grades = useGetGrades();
  const queryClient = useQueryClient();

  const put = usePut('/v1/auth/reg/student/' + student.id);
  const [notify, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  // Whenever `student` changes, update the form fields
  useEffect(() => {
    form.setFieldsValue({
      location: student.schoolId,
      firstName: student.firstName,
      lastName: student.lastName,
      grade: student.gradeId,
      birthDate: student.birthdate ? dayjs(student.birthdate) : dayjs(),
    });
  }, [student, form]);

  const handleOk = async () => {
    try {
      // Validate form fields
      const values = await form.validateFields();
      put.mutate(
        {
          schoolId: values.location,
          firstName: values.firstName,
          lastName: values.lastName,
          gradeId: values.grade,
          birthdate: dayjs(values.birthDate).endOf('day').toISOString(),
        },
        {
          onSuccess: () => {
            // Invalidate queries to refetch data
            queryClient.invalidateQueries(['/v1/auth/reg/students']);
            notify.success({
              message: 'Student updated successfully',
              description: `${values.firstName} ${values.lastName} has been updated.`,
            });
            onClose();
          },
          onError: () => {
            Swal.fire({
              title: 'Error',
              text: 'There was an error updating the student',
              icon: 'error',
            });
          },
        }
      );
    } catch (error) {
      // Form validation errors are caught here if any fields are invalid
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Edit Student"
        open={isOpen}
        onOk={handleOk}
        onCancel={onClose}
        okText="Save Student"
        confirmLoading={put.isLoading}
      >
        {schools.isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <Form form={form} layout="vertical">
              <Pt3>
                {/* LOCATION */}
                <Form.Item
                  name="location"
                  label="Choose Location"
                  rules={[{ required: true, message: 'Location is required' }]}
                >
                  <Select
                    placeholder="Select a location"
                    options={schools.data?.map((school) => ({
                      label: school.name,
                      value: school.id,
                    }))}
                  />
                </Form.Item>

                {/* FIRST & LAST NAME */}
                <Row>
                  <Col md={6}>
                    <Form.Item
                      name="firstName"
                      label="Student's First Name"
                      rules={[
                        { required: true, message: 'First name is required' },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      name="lastName"
                      label="Student's Last Name"
                      rules={[
                        { required: true, message: 'Last name is required' },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>

                {/* GRADE */}
                <Form.Item
                  name="grade"
                  label="Student's Grade"
                  rules={[{ required: true, message: 'Grade is required' }]}
                >
                  <Select
                    placeholder="Select grade"
                    options={grades?.data?.map((g) => ({
                      label: g.grade,
                      value: g.id,
                    }))}
                  />
                </Form.Item>

                {/* BIRTHDATE */}
                <Form.Item
                  name="birthDate"
                  label="Student's Birth Date"
                  rules={[
                    { required: true, message: 'Birth date is required' },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                </Form.Item>
              </Pt3>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default EditStudentModal;
