/**
 * @file Description.tsx
 * Displays descriptive information about a given program: grade info, location, etc.
 */
import React, { FC, useMemo } from 'react';
import { IMoreInfoDescriptionProps } from './types';
import dayjs from 'dayjs';

/**
 * Renders textual and descriptive information for a program.
 *
 * @param {IMoreInfoDescriptionProps} props - The props for the Description component.
 */
const Description: FC<IMoreInfoDescriptionProps> = ({
  program,
  gradeStatement,
  locationName,
}) => {
  const content = useMemo(() => {
    return (
      <>
        <p>{gradeStatement}</p>
        <p>{program?.generalTime}</p>
        <p>
          {dayjs(program?.startDate).format('MM/DD/YYYY')} -{' '}
          {dayjs(program?.endDate).format('MM/DD/YYYY')}
        </p>
        <p>Location: {locationName}</p>

        <hr className="mt-3 mb-3" />

        <div
          dangerouslySetInnerHTML={{
            __html: program?.description || '',
          }}
        />

        <hr className="mt-3 mb-3" />

        <p>
          <b>Registration Requirements</b>
        </p>

        <p>
          Students must be {gradeStatement}
          {program?.locationRequired && (
            <>
              <br />
              Students must also be enrolled in a school that corresponds with
              the STAR site at {locationName}.
            </>
          )}
        </p>

        <hr className="mt-3 mb-3" />
      </>
    );
  }, [program, gradeStatement, locationName]);

  return content;
};

export default Description;
