/**
 * @file StudentsListSection.tsx
 */
import React, { FC } from 'react';
import { Tooltip } from 'antd';

import { Pt2 } from '../../../../components/P'; // Example placeholder
import { StudentWithProps } from '../../../../hooks/useGetStudents';
import WeekSelector from './WeekSelector';
import { ISchedule } from './types'; // Adjust as needed

interface IStudentsListSectionProps {
  title: string;
  tooltip: string;
  studentsArray: StudentWithProps[];
  schedules: ISchedule[];
  program: any; // or your type
  gradeMinimum?: { id: number; shortGrade: string };
  gradeMaximum?: { id: number; shortGrade: string };

  // Additional concurrency-related props you might pass to WeekSelector:
  enrolledSchedules?: any[];
  currentSelections?: any[];
  concurrencyDiscounts?: any[];
  organizationalAccount?: any;
  schedulesBilledTo?: any[];

  // Handler to gather the final selection
  onChangeSelection?: (payload: any) => void;
}

const StudentsListSection: FC<IStudentsListSectionProps> = ({
  title,
  tooltip,
  studentsArray,
  schedules,
  program,
  gradeMinimum,
  gradeMaximum,
  enrolledSchedules = [],
  currentSelections = [],
  concurrencyDiscounts = [],
  organizationalAccount,
  schedulesBilledTo,
  onChangeSelection,
}) => {
  return (
    <div style={{ marginTop: 16 }}>
      <b>
        <Tooltip title={tooltip}>{title} Students For Registration:</Tooltip>
      </b>

      {studentsArray.map((student, index) => (
        <Pt2 key={`student_${student.id}_${index}`}>
          <table className="w-100">
            <tbody>
              <tr>
                <td>{student.firstName}:</td>
                <td className="text-right">
                  <small>
                    <i>Select A Schedule</i>
                  </small>
                </td>
              </tr>
            </tbody>
          </table>

          {/* If enrollmentBlocked is present, display a message */}
          {student.enrollmentBlocked?.id ? (
            <span style={{ color: 'red' }}>
              Ineligible for Enrollment - Expelled
            </span>
          ) : (
            <div>
              {/* Render a WeekSelector for each available schedule */}
              {schedules?.map((schedule: ISchedule) => (
                <div data-testid={'week-selector'} key={schedule.id}>
                  <WeekSelector
                    schedule={schedule}
                    program={program}
                    student={student}
                    gradeMinimum={gradeMinimum}
                    gradeMaximum={gradeMaximum}
                    // concurrency props
                    concurrencyDiscounts={concurrencyDiscounts}
                    currentSelections={currentSelections}
                    enrolledSchedules={enrolledSchedules}
                    organizationalAccount={organizationalAccount}
                    schedulesBilledTo={schedulesBilledTo}
                    onChangeSelection={(payload) => {
                      // Forward to parent or store in a local state
                      onChangeSelection?.({
                        ...payload,
                        schedule,
                      });
                    }}
                  />
                </div>
              ))}

              {/* Example placeholder for a component to display selected-schedule totals:
                  <SelectedScheduleTotal studentId={student.id} />
              */}
            </div>
          )}
        </Pt2>
      ))}

      <hr className="mt-3 mb-3" />
    </div>
  );
};

export default StudentsListSection;
