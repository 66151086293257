/**
 * @file Actions.tsx
 * Contains the set of action buttons for the MoreInfoModal.
 */
import React, { FC, useEffect } from 'react';
import { Button, Skeleton } from 'antd';
import { IMoreInfoModalActionsProps } from './types';
import { useNavigate } from 'react-router-dom';
import {
  useAdd,
  validateSelections,
} from '../../../../hooks/localSchedule/useAdd';
import Swal from 'sweetalert2';
import { useScheduleValidator } from '../../../../hooks/useScheduleValidator';

const Actions: FC<IMoreInfoModalActionsProps> = ({
  user,
  currentSelections,
  totalStudents,
  totalQualifiedStudents,
  onLoading,
  onClose,
}) => {
  const navigate = useNavigate();

  const validatedSelections = () => validateSelections(currentSelections);

  const scheduleValidator = useScheduleValidator();

  const addToSchedule = useAdd();

  const handleAddToSchedule = () => {
    if (validatedSelections().length > 0) {
      const selectionsToValidate = validatedSelections().map(
        (selection: any) => ({
          studentId: selection.studentId,
          scheduleId: selection.scheduleId,
        })
      );

      scheduleValidator
        .validateSchedules(selectionsToValidate)
        .then(() => {
          addToSchedule(validatedSelections());
          onClose();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Schedules added successfully!',
            confirmButtonText: 'OK',
            confirmButtonColor: '#3085d6',
          });
        })
        .catch((error: any) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message || 'An error occurred, please try again later.',
            confirmButtonText: 'OK',
          });
        });
    }
  };

  useEffect(() => {
    if (onLoading) {
      onLoading(scheduleValidator.isValidating);
    }
  }, [scheduleValidator.isValidating, onLoading]);

  if (scheduleValidator.isValidating) {
    return <Skeleton active={true} data-testid="loading-skeleton" />;
  }

  return (
    <div style={{ marginTop: 16 }}>
      <table className="w-100">
        <tbody>
          <tr>
            {(user || totalStudents > 0) && (
              <td style={{ width: 'calc(50% - 20px)' }}>
                <Button
                  block
                  type="primary"
                  className="btn-sac"
                  style={{ marginRight: 8 }}
                  onClick={() => navigate('/')}
                >
                  Edit Students
                </Button>
              </td>
            )}
            {totalQualifiedStudents > 0 && (
              <>
                <td className="text-center" style={{ width: '24px' }}>
                  <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                    or
                  </span>
                </td>
                <td style={{ width: 'calc(50% - 20px)' }}>
                  <Button
                    block
                    type="primary"
                    className="btn-sac"
                    onClick={handleAddToSchedule}
                    disabled={validatedSelections().length === 0}
                  >
                    Add to Schedule
                  </Button>
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Actions;
