import React, { useEffect } from 'react';
/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';

export type RegistrationWindow = {
  id: number;
  name?: string;
  policies?: string[] | string;
  title?: string;
};

type Props = {
  registrations: RegistrationWindow[];
  onPolicyChange?: (checked: boolean) => void;
  onTotalPolicies?: (count: number) => void;
};

// Styled components
const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`;

const Heading = styled.h4`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
`;

const PolicyTextContainer = styled.div`
  margin-bottom: 1rem;

  ul {
    margin-left: 1.5rem;
    list-style: disc;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxText = styled.span`
  margin-left: 0.5rem;
  color: #555;
`;

export default function PoliciesList({
  registrations,
  onPolicyChange,
  onTotalPolicies,
}: Props) {
  // Ensure registrations is always an array so hooks run on every render.
  const validRegistrations = registrations || [];

  // Count total policies
  const total = validRegistrations.reduce((acc, r) => {
    if (!r.policies) return acc;
    if (Array.isArray(r.policies)) {
      return r.policies.length > 0 ? acc + r.policies.length : acc;
    }
    return acc + 1; // String is considered one policy
  }, 0);

  // Always call the hook regardless of registrations content
  useEffect(() => {
    onTotalPolicies?.(total);
    // If there are no policies to check, automatically mark as checked
    if (total === 0) {
      onPolicyChange?.(true);
    }
  }, [total, onTotalPolicies, onPolicyChange]);

  // Early return only for rendering, not affecting hooks execution
  if (validRegistrations.length === 0) {
    return null;
  }

  return (
    <div data-testid="policies-list">
      {validRegistrations.map((registration) => {
        const { id, name, title, policies } = registration;
        const headingText = name || title || `Window ${id}`;

        // No policies
        if (!policies) {
          return (
            <Card key={id} data-testid={`registration-${id}`}>
              <Heading>{headingText}</Heading>
              <p data-testid={`no-policies-${id}`}>No policies found.</p>
            </Card>
          );
        }

        // Policies as a single HTML string
        if (typeof policies === 'string') {
          return (
            <Card key={id} data-testid={`registration-${id}`}>
              <Heading>{headingText}</Heading>
              <PolicyTextContainer
                dangerouslySetInnerHTML={{ __html: policies }}
              />
              <CheckboxContainer>
                <label>
                  <input
                    type="checkbox"
                    data-testid={`checkbox-${id}-0`}
                    onChange={(e) => onPolicyChange?.(e.target.checked)}
                  />
                  <CheckboxText>
                    I have read and understand these policies
                  </CheckboxText>
                </label>
              </CheckboxContainer>
            </Card>
          );
        }

        // Policies as an array
        return (
          <Card key={id} data-testid={`registration-${id}`}>
            <Heading>{headingText}</Heading>
            {policies.length > 0 ? (
              policies.map((policy, index) => (
                <CheckboxContainer key={`${id}-${index}`}>
                  <label>
                    <input
                      type="checkbox"
                      data-testid={`checkbox-${id}-${index}`}
                      onChange={(e) => onPolicyChange?.(e.target.checked)}
                    />
                    <CheckboxText>{policy}</CheckboxText>
                  </label>
                </CheckboxContainer>
              ))
            ) : (
              <p data-testid={`no-policies-${id}`}>No policies found.</p>
            )}
          </Card>
        );
      })}
    </div>
  );
}
