// useGetSchedules.ts
import { useGet } from './useApi';
import { Schedule } from '../shared-typings/models/schedule.model';

/**
 * Se a API retorna schedules agrupados (ex: { [programListingId]: Schedule[] }),
 * então aqui você pode tipar como Record<number, Schedule[]> ou similar.
 */
export const useGetSchedules = () => {
  return useGet<{ [index: number]: Schedule[] }>('/v1/public/reg/schedules', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
