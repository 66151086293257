import React, { FC, useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import styled from 'styled-components';
import { Pb3, Pt3, Pt4 } from '../../../components/P';
import { useGetProgramTypes } from '../../../hooks/useGetProgramTypes';
import { useGetEnrollingRegions } from '../../../hooks/useGetEnrollingRegions';

const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export type OnCloseArgs = {
  selectedProgramTypes: number[];
  selectedRegions: number[] | ['any'];
};

interface ISetupModalProps {
  isOpen: boolean;
  onClose: (args: OnCloseArgs) => void;
}

const SetupModal: FC<ISetupModalProps> = ({ isOpen, onClose }) => {
  const programTypes = useGetProgramTypes();
  const enrollingRegions = useGetEnrollingRegions();

  const [selectedProgramTypes, setSelectedProgramTypes] = useState<number[]>(
    []
  );
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [anyRegion, setAnyRegion] = useState<boolean>(false);

  const handleOk = () => {
    onClose({
      selectedProgramTypes,
      selectedRegions: anyRegion ? ['any'] : selectedRegions.map((x) => +x),
    });
  };

  const onChangeProgramTypes = (checkedValues: any) => {
    setSelectedProgramTypes(checkedValues);
  };

  const onChangeRegions = (checkedValues: any) => {
    setSelectedRegions(checkedValues);
  };

  const onChangeAnyRegion = (e: any) => {
    const checked = e.target.checked;
    setAnyRegion(checked);
    if (checked) {
      setSelectedRegions([]);
    }
  };

  return (
    <Modal
      title="Filter Programs"
      okText="Apply Filter"
      open={isOpen}
      onOk={handleOk}
      closable={false}
      footer={null}
      loading={programTypes.isLoading || enrollingRegions.isLoading}
    >
      <Pt3>
        <hr />
      </Pt3>

      <Pt4>
        <div className="text-center">
          <Pb3>
            <Title>What program types are you looking for?</Title>
          </Pb3>

          <Checkbox.Group
            value={selectedProgramTypes}
            onChange={onChangeProgramTypes}
          >
            {programTypes.data?.map((programType) => (
              <div
                key={programType.id}
                style={{ marginBottom: 8, width: '100%' }}
              >
                <Checkbox value={programType.id}>
                  {programType.longName}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </div>
      </Pt4>

      <Pt4>
        <hr />
      </Pt4>

      <Pt4>
        <div className="text-center">
          <Pb3>
            <Title>
              Please choose the regions that your students are most likely to
              attend
            </Title>
          </Pb3>

          <Checkbox.Group value={selectedRegions} onChange={onChangeRegions}>
            <div style={{ marginBottom: 8, width: '100%' }}>
              <Checkbox
                checked={anyRegion}
                onChange={onChangeAnyRegion}
                disabled={selectedProgramTypes.length === 0}
              >
                Any Region
              </Checkbox>
            </div>
            {enrollingRegions.data?.map((region) => (
              <div key={region.id} style={{ marginBottom: 8, width: '100%' }}>
                <Checkbox
                  disabled={anyRegion || selectedProgramTypes.length === 0}
                  value={region.id}
                >
                  {region.name}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </div>
      </Pt4>

      <Pt4>
        <hr />
      </Pt4>

      <Pt4>
        <Button
          type={'primary'}
          size={'large'}
          block
          onClick={handleOk}
          disabled={
            selectedProgramTypes.length === 0 || selectedRegions.length === 0
          }
        >
          Filter
        </Button>
      </Pt4>
    </Modal>
  );
};

export default SetupModal;
