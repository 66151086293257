/**
 * @file index.tsx (MoreInfoModal)
 * The main container component for the MoreInfoModal, orchestrating data fetching & subcomponent rendering.
 */
import React, { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { Modal, Button, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// Example placeholders for data
import { useGetSchedules } from '../../../../hooks/useGetSchedules';
import { useGetSchools } from '../../../../hooks/useGetSchools';
import { useGetEnrollingSchools } from '../../../../hooks/useGetEnrollingSchools';
import { useFetchUser } from '../../../../hooks/useFetchUser';
import { useGetPromotionDay } from '../../../../hooks/useGetPromotionDay';
import { useGetRegistrationWindows } from '../../../../hooks/useGetRegistrationWindows';
import {
  useGetStudents,
  StudentWithProps,
} from '../../../../hooks/useGetStudents';

// Local components
import Description from './Description';
import StudentsListSection from './StudentsListSection';
import Actions from './Actions';

// Types
import { IMoreInfoModalProps, ISchedule } from './types'; // Adjust path
import { qualified } from '../../../../utils/studentHelpers';

const MoreInfoModal: FC<IMoreInfoModalProps> = ({
  isOpen,
  onClose,
  program,
  gradeMinimum,
  gradeMaximum,
}) => {
  const navigate = useNavigate();

  /**
   * ----- Data Fetching Hooks -----
   */
  const { data: user, isLoading: isLoadingUser } = useFetchUser();
  const { data: allSchedules, isLoading: isLoadingSchedules } =
    useGetSchedules();
  const { data: schools, isLoading: isLoadingSchools } = useGetSchools();
  const { data: enrollingSchools, isLoading: isLoadingEnrollingSchools } =
    useGetEnrollingSchools();
  const { data: registrationWindows, isLoading: isLoadingRegistrationWindows } =
    useGetRegistrationWindows();
  const { data: students, isLoading: isLoadingStudents } = useGetStudents();
  const { data: promotionDayData, isLoading: isLoadingPromotionDay } =
    useGetPromotionDay();

  const isLoading =
    isLoadingUser ||
    isLoadingSchedules ||
    isLoadingSchools ||
    isLoadingEnrollingSchools ||
    isLoadingRegistrationWindows ||
    isLoadingStudents ||
    isLoadingPromotionDay;

  /**
   */
  const [currentSelections, setCurrentSelections] = useState<any[]>([]);
  const [actionsLoading, setActionsLoading] = useState<boolean>(false);
  const [modalMounted, setModalMounted] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && !modalMounted) {
      setModalMounted(true);
    } else if (!isOpen && modalMounted) {
      setModalMounted(false);
    }
  }, [isOpen, modalMounted]);

  /**
   * ----- Derived Data -----
   */
  const schedules: ISchedule[] = useMemo(() => {
    return (allSchedules?.[program?.id] || []) as ISchedule[];
  }, [allSchedules, program?.id]);

  const eligibleStudents = useMemo(() => {
    return (students || []).filter((student) => student?.id > 0);
  }, [students]);

  const tentativeStudents = useMemo(() => {
    return (students || []).filter((student) => student?.id < 0);
  }, [students]);

  const promotionDay = dayjs(promotionDayData).toDate();
  const beforePromotionDay = promotionDayData
    ? dayjs().isBefore(promotionDay)
    : false;

  const registrationWindow = useMemo(() => {
    return (registrationWindows || []).find(
      (item) => item.id === program?.registrationWindowId
    );
  }, [registrationWindows, program?.registrationWindowId]);

  const needsPromotionThisYear = useCallback((): boolean => {
    if (!registrationWindow) return false;
    const currentYear = new Date().getFullYear();
    const regYear = new Date(registrationWindow?.startDate).getFullYear();

    if (currentYear > regYear) return false;
    if (!beforePromotionDay) return false;
    return true;
  }, [beforePromotionDay, registrationWindow]);

  const locationName = useCallback((): string => {
    const found = (enrollingSchools || []).find(
      (school) => school.id === Number(program?.schoolId)
    );
    return found?.name || '';
  }, [enrollingSchools, program?.schoolId]);

  const gradeStatement = useCallback((): string => {
    if (!gradeMinimum || !gradeMaximum) return '';

    if (gradeMinimum.shortGrade !== gradeMaximum.shortGrade) {
      return `${gradeMinimum.shortGrade} - ${gradeMaximum.shortGrade} grade${
        needsPromotionThisYear()
          ? ' in the current school year.'
          : ' in the Fall.'
      }`;
    }
    return gradeMinimum.shortGrade;
  }, [gradeMinimum, gradeMaximum, needsPromotionThisYear]);

  const isStudentQualified = useCallback(
    (student: StudentWithProps): boolean => {
      console.log(
        `Checking student: ${student.id} - ${student.firstName} ${student.lastName}`
      );

      // Must have schools loaded
      if (!schools || !schools.length) {
        console.warn(`Student ${student.id}: Schools not loaded or empty.`);
        return false;
      }

      // Must have valid grade range
      if (!gradeMinimum?.grade || !gradeMaximum?.grade) {
        console.warn(
          `Student ${student.id}: Grade range (min/max) is invalid.`
        );
        return false;
      }

      // Must pass external "qualified" check
      if (!qualified(student)) {
        console.warn(
          `Student ${student.id}: Failed external "qualified" check.`
        );
        return false;
      }

      // If program requires location alignment
      if (program?.locationRequired) {
        const studentSchool = schools.find(
          (s) => s.id === Number(student.schoolId)
        );
        // Check if student school exists
        if (!studentSchool) {
          console.warn(
            `Student ${student.id}: School not found for ID ${student.schoolId}`
          );
          return false;
        }
        const programSchoolId = Number(program?.schoolId);
        const validLocation =
          studentSchool.siteLocationId === programSchoolId ||
          studentSchool.id === programSchoolId;

        if (!validLocation) {
          console.warn(
            `Student ${student.id}: Location alignment check failed. Student school: ${studentSchool.id}, Program school: ${programSchoolId}`
          );
          return false;
        }
      }

      // Must have gradeId within the program's min/max
      if (student.gradeId === undefined || student.gradeId === null) {
        console.warn(`Student ${student.id}: Missing gradeId.`);
        return false;
      }

      // Ensure we're comparing numbers - Convert everything to numbers explicitly
      const studentGradeId = Number(student.gradeId);
      const minGradeId = Number(gradeMinimum.id || 0);
      const maxGradeId = Number(gradeMaximum.id || 0);
      console.log(
        `Student ${student.id}: Grade check - Student grade: ${studentGradeId}, Min grade: ${minGradeId}, Max grade: ${maxGradeId}`
      );

      const isInGradeRange =
        studentGradeId >= minGradeId && studentGradeId <= maxGradeId;
      if (!isInGradeRange) {
        console.warn(
          `Student ${student.id}: Grade ${studentGradeId} is out of range (${minGradeId}-${maxGradeId}).`
        );
        return false;
      }

      console.log(`Student ${student.id}: Passed all checks.`);
      return true;
    },
    [schools, gradeMinimum, gradeMaximum, program]
  );

  const handleChangeSelection = useCallback(
    (payload: any) => {
      // Merge the payload with the current program info.
      const completePayload = { ...payload, program };

      setCurrentSelections((prevSelections) => {
        // Find an existing selection for the same student, schedule, and program.
        const index = prevSelections.findIndex(
          (selection) =>
            selection?.studentId === completePayload?.studentId &&
            selection?.program.id === completePayload?.program.id &&
            selection?.schedule.id === completePayload?.schedule.id
        );

        // If found, check if the payload is identical.
        if (index !== -1) {
          const existing = prevSelections[index];
          if (JSON.stringify(existing) === JSON.stringify(completePayload)) {
            // Nothing changed; return the previous state to avoid re-render.
            return prevSelections;
          }
          // Otherwise, replace the existing selection.
          const newSelections = [...prevSelections];
          newSelections[index] = completePayload;
          return newSelections;
        }
        // If not found, add the new selection.
        return [...prevSelections, completePayload];
      });
    },
    [program]
  );

  /**
   * ----- Counting -----
   */
  const totalStudents = useMemo(() => {
    return eligibleStudents.length + tentativeStudents.length;
  }, [eligibleStudents, tentativeStudents]);

  const totalQualifiedStudents = useMemo(() => {
    const eligCount = eligibleStudents.filter(isStudentQualified).length;
    const tentCount = tentativeStudents.filter(isStudentQualified).length;
    return eligCount + tentCount;
  }, [eligibleStudents, tentativeStudents, isStudentQualified]);

  const qualifiedEligibleStudents = useMemo(() => {
    return eligibleStudents.filter(isStudentQualified);
  }, [eligibleStudents, isStudentQualified]);

  const qualifiedTentativeStudents = useMemo(() => {
    return tentativeStudents.filter(isStudentQualified);
  }, [tentativeStudents, isStudentQualified]);

  const handleModalClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return (
      <Modal
        title={program?.title || 'Program Info'}
        open={isOpen}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose={false}
      >
        <Skeleton active={true} />
      </Modal>
    );
  }

  /**
   * ----- Early Return if no schedules -----
   */
  if (!schedules?.length) {
    return (
      <Modal
        title={program?.title || 'Program Info'}
        open={isOpen}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose={false}
      >
        <p className="text-center" style={{ color: 'red' }}>
          This program has no currently running schedules.
        </p>
      </Modal>
    );
  }

  if (!user) {
    return (
      <Modal
        title={program?.title || 'Program Info'}
        open={isOpen}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose={false}
      >
        <Description
          program={program}
          gradeStatement={gradeStatement()}
          locationName={locationName()}
        />

        <p className="text-center" style={{ color: 'orange', marginTop: 16 }}>
          You are currently logged out, please log in to proceed.
        </p>
        <div style={{ marginTop: 16 }}>
          <Button
            type="primary"
            size="large"
            block
            onClick={() => navigate('/login')}
          >
            Log In
          </Button>
        </div>
      </Modal>
    );
  }

  /**
   * ----- Render -----
   */
  return (
    <Modal
      title={program?.title || 'Program Info'}
      open={isOpen}
      onCancel={handleModalClose}
      footer={null}
      destroyOnClose={false}
    >
      <Description
        program={program}
        gradeStatement={gradeStatement()}
        locationName={locationName()}
      />

      {!actionsLoading && (
        <>
          {qualifiedEligibleStudents.length > 0 && (
            <StudentsListSection
              title="Eligible"
              tooltip="These are students that already exist on your family account. You can select schedules for these students."
              studentsArray={qualifiedEligibleStudents}
              schedules={schedules}
              program={program}
              onChangeSelection={handleChangeSelection}
            />
          )}

          {qualifiedTentativeStudents.length > 0 && (
            <StudentsListSection
              title="Tentative"
              tooltip="These students are not yet fully added to your family account. You can select a schedule, but must complete their info before finalizing."
              studentsArray={qualifiedTentativeStudents}
              schedules={schedules}
              program={program}
              onChangeSelection={handleChangeSelection}
            />
          )}

          {totalStudents === 0 && (
            <p style={{ color: 'orange', marginTop: 16 }}>
              Currently, you do not have any students.
            </p>
          )}
        </>
      )}

      <Actions
        user={user}
        currentSelections={currentSelections}
        totalStudents={totalStudents}
        totalQualifiedStudents={totalQualifiedStudents}
        onLoading={setActionsLoading}
        onClose={handleModalClose}
      />
    </Modal>
  );
};

export default MoreInfoModal;
