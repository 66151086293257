import React from 'react';
import LoginPage from './pages/Login/LoginPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import HomePage from './pages/Home/HomePage';
import { useFetchUser } from './hooks/useFetchUser';
import SchedulingPage from './pages/Scheduling/SchedulingPage';
import FinalizePage from './pages/Finalize/FinalizePage';

const App: React.FC = () => {
  useFetchUser();

  return (
    <div id="App">
      <Router>
        <Routes>
          <Route path="/login" Component={LoginPage} />
          <Route path="/scheduling" Component={SchedulingPage} />
          <Route
            path="/"
            element={
              <AuthGuard>
                <HomePage />
              </AuthGuard>
            }
          />
          <Route
            path="/finalize/:currentlySchedulingId?/:paymentIntentId?"
            element={
              <AuthGuard>
                <FinalizePage />
              </AuthGuard>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
