// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC } from 'react';

import { useFetchUser } from '../../hooks/useFetchUser';
import { Skeleton } from 'antd';

import './HomePage.css';
import SimplePage from '../../layout/SimplePage/SimplePage';
import Container from '../../layout/Container/Container';
import StudentsDiv from './components/StudentsDiv';

const HomePage: FC = () => {
  const user = useFetchUser();

  if (user.isLoading) {
    return (
      <SimplePage>
        <Container>
          <Skeleton active={true} />
        </Container>
      </SimplePage>
    );
  }

  return (
    <SimplePage>
      <Container>
        <StudentsDiv />
      </Container>
    </SimplePage>
  );
};

export default HomePage;
