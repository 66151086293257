// useGetRegistrationWindows.ts
import { useGet } from './useApi';
// import { RegistrationWindow } from '../shared-typings/models/registration-window.model';

export const useGetRegistrationWindows = () => {
  return useGet<any[]>('/v1/public/reg/registration-windows', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
