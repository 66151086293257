// useGetEnrollingRegions.ts
import { useGet } from './useApi';
import { EnrollingRegion } from '../shared-typings/models/enrolling-region';

export const useGetEnrollingRegions = () => {
  return useGet<EnrollingRegion[]>('/v1/public/reg/enrolling-regions', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
