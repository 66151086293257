import { useGet } from './useApi';
import { Student } from '../models/student.model';

export type StudentWithProps = Student & {
  additionalGuardians: any[];
  emergencyContacts: any[];
  medicalInfo: any;
  sharingInfo: any;
  enrollmentBlocked: any;
};

export const useGetStudents = () => {
  return useGet<StudentWithProps[]>('/v1/auth/reg/students', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
