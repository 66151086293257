// useGetGrades.ts
import { useGet } from './useApi';
import { Grade } from '../shared-typings/models/grade.model';

export const useGetGrades = () => {
  return useGet<Grade[]>('/v1/public/reg/grades', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
