/**
 * @file useAdd.ts
 */

import { useCallback, useEffect } from 'react';
import { useContextValue } from '../../Context';
import dayjs from 'dayjs';

export const validateSelections = (currentSelections: any) =>
  currentSelections.filter(
    (x: any) =>
      x.monday ||
      x.tuesday ||
      x.wednesday ||
      x.thursday ||
      x.friday ||
      x.saturday ||
      x.sunday
  );

export const useAdd = (callback?: (newCurrentSelections: any) => any) => {
  const { state, setState } = useContextValue();

  const addToSchedule = useCallback(
    (newSelections: any) => {
      setState((prevState) => {
        const validatedSelections = validateSelections(newSelections);

        // If there are no valid selections, clear currentSelections.
        if (validatedSelections.length === 0) {
          return {
            ...prevState,
            currentSelections: null,
          };
        }

        const prevCurrentSelections = prevState.currentSelections;

        if (prevCurrentSelections && prevCurrentSelections.createdAt) {
          const prevSelections = prevCurrentSelections.selections || [];
          const updatedSelections = [...prevSelections];

          validatedSelections.forEach((selection: any) => {
            const index = prevSelections.findIndex(
              (existing: any) =>
                existing?.studentId === selection?.studentId &&
                existing?.program?.id === selection?.program?.id &&
                existing?.schedule?.id === selection?.schedule?.id
            );

            if (index !== -1) {
              // Update only if different
              if (
                JSON.stringify(prevSelections[index]) !==
                JSON.stringify(selection)
              ) {
                updatedSelections[index] = selection;
              }
            } else {
              updatedSelections.push(selection);
            }
          });

          return {
            ...prevState,
            currentSelections: {
              createdAt: prevCurrentSelections.createdAt,
              validTo: dayjs(prevCurrentSelections.createdAt)
                .add(15, 'minutes')
                .toDate(),
              selections: updatedSelections,
            },
          };
        } else {
          return {
            ...prevState,
            currentSelections: {
              createdAt: new Date(),
              validTo: dayjs().add(15, 'minutes').toDate(),
              selections: validatedSelections,
            },
          };
        }
      });
    },
    [setState]
  );

  // Side effects: update localStorage and trigger the callback whenever currentSelections changes.
  useEffect(() => {
    if (
      state.currentSelections &&
      state.currentSelections.selections?.length > 0
    ) {
      localStorage.setItem(
        'currentSelections',
        JSON.stringify(state.currentSelections)
      );
      if (callback) {
        callback(state.currentSelections);
      }
    } else {
      localStorage.removeItem('currentSelections');
    }
  }, [state.currentSelections, callback]);

  return addToSchedule;
};
