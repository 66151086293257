import { StudentWithProps } from '../hooks/useGetStudents';

export const qualified = (student: StudentWithProps) => {
  if (!student.id) return false;
  if (!student.additionalGuardians?.length) return false;
  if (!student.emergencyContacts?.length) return false;
  if (!student.medicalInfo?.id) return false;
  if (!student.sharingInfo?.id) return false;
  return true;
};
