// useGetScheduleBilledTo.ts
import { useGet } from './useApi';
// import { ScheduleBilledTo } from '../shared-typings/models/schedule-billed-to.model';

export const useGetScheduleBilledTo = () => {
  return useGet<any[]>('/v1/public/reg/schedule-billed-to', {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
};
