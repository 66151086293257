import React from 'react';
import { Modal, Button, Typography, Row, Col } from 'antd';
import { useRemove } from '../../../hooks/localSchedule/useRemove';
import { useGet } from '../../../hooks/localSchedule/useGet';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const { Text } = Typography;

interface SelectedSchedulesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SelectedSchedulesModal: React.FC<SelectedSchedulesModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const getSchedule = useGet();
  const removeFromSchedule = useRemove();

  // Sempre atribua a variável selections, mesmo que vazia
  const selections = getSchedule?.selections || [];

  // Utility function para formatar datas como "Sep 16, 2024"
  const formatDate = (dateStr: string | Date | undefined): string => {
    if (!dateStr) return '';
    const date = dayjs(dateStr).endOf('day').toDate();
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  // Remove um agendamento pelo índice no array de seleções.
  const handleRemove = (indexToRemove: number) =>
    removeFromSchedule(indexToRemove);

  const handleFinalizeAndPay = () => {
    navigate('/finalize');
  };

  const handleContinueScheduling = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      title="Selected Schedules"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="continue" onClick={handleContinueScheduling}>
          Continue Scheduling
        </Button>,
        <Button key="pay" type="primary" onClick={handleFinalizeAndPay}>
          Finalize and Pay
        </Button>,
      ]}
    >
      {selections.length === 0 ? (
        <div>No schedules selected</div>
      ) : (
        selections.map((item, idx) => {
          const { fee, schedule, program, student } = item;

          // Get title from REG_program.listings if available
          const titleString = program.title
            ? `${program.title}`
            : `${schedule.name}: ${program.group}`;

          const formattedStartDate = formatDate(program.startDate);
          const formattedEndDate = formatDate(program.endDate);
          const dateRange = `${formattedStartDate} - ${formattedEndDate}`;

          // Deriva as abreviações dos dias a partir dos booleans do schedule.
          const days = [];
          if (item.monday) days.push('M');
          if (item.tuesday) days.push('T');
          if (item.wednesday) days.push('W');
          if (item.thursday) days.push('TH');
          if (item.friday) days.push('F');
          const dayString = days.join('/');

          let studentName = 'Student';

          if (student) {
            studentName = student.firstName + ' ' + student.lastName;
          }

          return (
            <div
              key={idx}
              style={{
                border: '1px solid #f0f0f0',
                padding: '16px',
                marginBottom: '16px',
                borderRadius: '4px',
              }}
            >
              <Row>
                <Col span={24}>
                  <Text strong style={{ fontSize: '16px' }}>
                    {studentName}
                  </Text>
                </Col>
              </Row>

              <Row style={{ margin: '4px 0' }}>
                <Col span={24}>
                  <Text>{titleString}</Text>
                </Col>
              </Row>
              <Row style={{ margin: '4px 0' }}>
                <Col span={24}>
                  <Text>
                    {dateRange}: {dayString}
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: '8px' }}>
                <Col flex="auto">
                  <Text style={{ fontWeight: 'bold' }}>${fee}</Text>
                </Col>
                <Col>
                  <Text
                    onClick={() => handleRemove(idx)}
                    style={{ color: '#1890ff', cursor: 'pointer' }}
                  >
                    Remove
                  </Text>
                </Col>
              </Row>
            </div>
          );
        })
      )}
    </Modal>
  );
};

export default SelectedSchedulesModal;
