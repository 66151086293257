// index.tsx (ConfirmPolicies)

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result, Skeleton } from 'antd';

import { useGet as useGetLocalSchedule } from '../../../../hooks/localSchedule/useGet';
import { useGetSchedules } from '../../../../hooks/useGetSchedules';
import { useGetProgramListings } from '../../../../hooks/useGetProgramListings';
import { useGetRegistrationWindows } from '../../../../hooks/useGetRegistrationWindows';
import PoliciesList from './PoliciesList';
import OnError from '../../../../components/OnError';
import { Container } from 'react-bootstrap';
import NextStepButton from '../../components/NextStepButton';
import { Pb3 } from '../../../../components/P';

interface ConfirmPoliciesProps {
  onNextStep: () => void;
}

export default function ConfirmPolicies({ onNextStep }: ConfirmPoliciesProps) {
  const navigate = useNavigate();

  // Track how many boxes are checked & how many total
  const [checkedCount, setCheckedCount] = useState(0);
  const [totalPolicies, setTotalPolicies] = useState(0);

  // Hooks
  const localSchedule = useGetLocalSchedule(true);
  const { data: schedulesObject, isLoading: loadingSchedules } =
    useGetSchedules();
  const { data: programListingsObject, isLoading: loadingPrograms } =
    useGetProgramListings();
  const { data: registrationWindows, isLoading: loadingWindows } =
    useGetRegistrationWindows();

  // Loading
  if (loadingSchedules || loadingPrograms || loadingWindows) {
    return (
      <div data-testid="skeleton-loading">
        <Skeleton active />
      </div>
    );
  }

  // Missing data
  if (
    !localSchedule ||
    !schedulesObject ||
    !programListingsObject ||
    !registrationWindows
  ) {
    const missingData: string[] = [];
    if (!localSchedule) missingData.push('Your Scheduling');
    if (!schedulesObject) missingData.push('Schedules');
    if (!programListingsObject) missingData.push('Program Listings');
    if (!registrationWindows) missingData.push('Registration Windows');

    return (
      <OnError
        title={`Missing: ${missingData.join(', ')}`}
        subTitle="Please try to schedule again"
        extra={
          <Button type="dashed" onClick={() => navigate('/scheduling')}>
            Go Back
          </Button>
        }
        status={404}
      />
    );
  }

  // Convert objects to arrays
  const schedulesData = Object.values(schedulesObject);
  const programListingsData = Object.values(programListingsObject).flat();

  // Atenção: se localSchedule.selections for um objeto com IDs como chaves,
  // precisamos transformá-lo em array do jeito certo:
  //
  // Exemplo:
  // localSchedule.selections = {
  //   "1234": { day: "MONDAY", ... },
  //   "5678": { day: "TUESDAY", ... }
  // }
  //
  // Então, precisamos de algo como:
  // [
  //   { scheduleId: 1234, ...todo resto do objeto... },
  //   { scheduleId: 5678, ... }
  // ]
  //
  // Se localSchedule.selections já for array, basta usar direto.
  // Caso contrário, faça:
  const selectionsArray = Array.isArray(localSchedule.selections)
    ? localSchedule.selections
    : Object.entries(localSchedule.selections).map(([key, value]) => {
        return {
          scheduleId: key,
          ...value,
        };
      });

  // Função para achar os registration windows distintos
  function getDistinctRegistrationWindows(selectionList: any[]) {
    const windows = selectionList
      .map((sel) => {
        let schedule = null;

        if (Array.isArray(schedulesData)) {
          const scheduleArray = schedulesData.flat();
          schedule = scheduleArray.find((item) => item.id === sel.scheduleId);
        }

        if (!schedule) {
          return null;
        }

        // Converte schedule para any para evitar problemas de tipagem
        const scheduleAny = schedule as any;
        const programId = scheduleAny?.programListingId || 0;
        const program = programListingsData?.find(
          (p: any) => +p.id === +programId
        );

        if (!program) {
          return null;
        }

        return registrationWindows?.find(
          (rw: any) => +rw.id === +(program.registrationWindowId || 0)
        );
      })
      .filter((window, index, self) => {
        return (
          window && self.findIndex((w: any) => w?.id === window.id) === index
        );
      });

    return windows || [];
  }

  const allRegistrations = getDistinctRegistrationWindows(selectionsArray);

  // Se não encontrou nenhuma RegistrationWindow, exiba "You schedule has no policies..."
  if (!allRegistrations.length) {
    return (
      <Container>
        <Result
          status="success"
          title="Success"
          subTitle="You schedule has no policies to confirm"
        />
        <NextStepButton disabled={false} onClick={() => onNextStep()} />
      </Container>
    );
  }

  // Callbacks para PoliciesList
  function handlePolicyChange(checked: boolean) {
    setCheckedCount((prev) => (checked ? prev + 1 : prev - 1));
  }
  function handleTotalPolicies(count: number) {
    setTotalPolicies(count);
  }
  const allPoliciesChecked =
    totalPolicies === 0 ||
    (totalPolicies > 0 && checkedCount === totalPolicies);

  function handleNextStep() {
    if (allPoliciesChecked) {
      onNextStep();
    } else {
      alert('Please confirm all policies before proceeding.');
    }
  }

  return (
    <>
      <Pb3>
        <PoliciesList
          registrations={allRegistrations}
          onPolicyChange={handlePolicyChange}
          onTotalPolicies={handleTotalPolicies}
        />
      </Pb3>
      <NextStepButton disabled={!allPoliciesChecked} onClick={handleNextStep} />
    </>
  );
}
